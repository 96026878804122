import { useContext } from 'react'

import PageContext from '@contexts/pageContext'

const launchClientResourceModal = (resource, showClientResourceModal) => new Promise((resolve, reject) => {
  if (showClientResourceModal){
    const payload = {
      resource,
    }

    showClientResourceModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showClientResourceModal not defined in PageContext callbacks'))
})

function useClientResource(){
  const { callbacks } = useContext(PageContext)
  const { showClientResourceModal } = callbacks || {}

  return {
    callbacks: {
      launchClientResourceModal: resource => launchClientResourceModal(resource, showClientResourceModal),
    },
  }
}

export default useClientResource
