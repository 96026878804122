/* eslint-disable array-callback-return */
import {
  Document, Packer, Paragraph, TextRun,
} from 'docx'

import { toast } from 'react-toastify'

const getDetailSort = (value) => {
  switch (value){
    case 'Bed': return 1
    case 'Study': return 2
    case 'Bath': return 3
    case 'Car': return 4
    default: return 5
  }
}

export async function generateCopyDocxFile(serviceJob, serviceJobDetails, copyTemplates){
  const newLine = new Paragraph({ style: 'default', children: [] })

  const details = serviceJobDetails?.filter(x => (
    ['Bath', 'Bed', 'Car', 'Study'].includes(x.entityFieldType.name)
  )).filter(y => JSON.parse(y.value).value > 0).map(detail => ({
    name: detail.entityFieldType.name,
    pluralName: detail.entityFieldType.name === 'Study' ? 'Studies' : `${detail.entityFieldType.name }s`,
    sort: getDetailSort(detail.entityFieldType.name),
    value: JSON.parse(detail.value).value,
  })) || []

  const fileDetails = [
    new Paragraph({
      style: 'boldUnderline',
      text: serviceJob.campaign.name,
    }),
  ]

  if (details.length){
    fileDetails.push(new Paragraph({
      style: 'underline',
      children: details.sort((a, b) => (a.sort > b.sort ? 1 : -1)).map((detail, index) => (
        new TextRun({
          text: `${detail.value > 1 ? `${detail.value } ` : ''}`
            + `${detail.value > 1 ? detail.pluralName : detail.name}`
            + `${index !== details.length - 1 ? ' / ' : ''}`,
        })
      )),
    }))
  }

  fileDetails.push(newLine)

  copyTemplates.sort((a, b) => (a.copy.copyTemplate.displayName > b.copy.copyTemplate.displayName ? 1 : -1)).map(({ copy }) => {
    // template
    fileDetails.push(newLine)
    fileDetails.push(new Paragraph({
      style: 'underline',
      children: [
        new TextRun({ text: copy.copyTemplate.name }),
      ],
    }))
    fileDetails.push(newLine)
    // details
    copy.copyDetails.sort((a, b) => (a.copyTemplateField.sort > b.copyTemplateField.sort ? 1 : -1)).map((copyDetail, index) => {
      if (copyDetail.value !== ''){
        fileDetails.push(new Paragraph({
          style: copyDetail.copyTemplateField.name.match('Heading') ? 'bold' : 'default',
          text: copyDetail.value,
          ...copyDetail.copyTemplateField.bulletPoint && { bullet: {
              level: 0,
          } },
        }))

        // new line
        if (copyDetail.copyTemplateField.name.match('Heading') || copyDetail.copyTemplateField.name.match('Introduction')
          || copyDetail.copyTemplateField.name.match('Body') || copy.copyDetails.length - 1 === index){
          fileDetails.push(newLine)
        }
      }
    })
  })

  const doc = new Document({
    styles: {
      paragraphStyles: [
        {
          id: 'default',
          name: 'Default',
          run: {
            font: 'Arial',
            size: 18,
          },
        },
        {
          id: 'underline',
          name: 'Underline',
          basedOn: 'default',
          run: { underline: true },
        },
        {
          id: 'boldUnderline',
          name: 'Bold Underline',
          basedOn: 'default',
          run: {
            underline: true,
            bold: true,
          },
        },
        {
          id: 'bold',
          name: 'Bold',
          basedOn: 'default',
          run: { bold: true },
        },
      ],
    },
    sections: [
      {
        properties: {},
        children: [...fileDetails],
      },
    ],
  })

  return doc
}

export async function downloadDocxFile(serviceJob, serviceJobDetails, copyTemplates){
  const doc = await generateCopyDocxFile(serviceJob, serviceJobDetails, copyTemplates)
  const fileName = `CopyProof_${(serviceJob.campaign.name).replaceAll(/\s/g, '')}`

  Packer.toBlob(doc).then((blob) => {
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', `${fileName}.docx`)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  })
}

const createAsset = (assetParams, createFn, updateAssetCallback) => {
  createFn(assetParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
    }
    updateAssetCallback()
  })
}

const updateAsset = (assetParams, updateFn, updateAssetCallback) => {
  updateFn(assetParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
    }
    updateAssetCallback()
  })
}

const saveAsset = (serviceJob, file, fileName, assetFile, updateFn, createFn, updateAssetCallback) => {
  const formData = new FormData()
  formData.append('ServiceJobId', serviceJob.id)
  formData.append('Description', 'Copy Proof Docx')
  formData.append('FileByte', file)
  formData.append('FileName', fileName)

  if (assetFile){
    const asset = {
      id: assetFile.id,
      data: formData,
    }
    updateAsset(asset, updateFn, updateAssetCallback)
  } else {
    createAsset(formData, createFn, updateAssetCallback)
  }
}

export async function saveDocxFile(serviceJob, serviceJobDetails, copyTemplates, createFn, updateFn, assetFile, updateAssetCallback, generateFile){
  const fileName = `CopyProof_${(serviceJob.campaign.name).replaceAll(/\s/g, '')}`

  if (generateFile){
    const doc = await generateCopyDocxFile(serviceJob, serviceJobDetails, copyTemplates)
    Packer.toBlob(doc).then((blob) => {
      const file = new File([blob], 'CopyProof.docx', {
        type: blob.type,
      })
      saveAsset(serviceJob, file, fileName, assetFile, updateFn, createFn, updateAssetCallback)
    })
  } else {
    const req = new XMLHttpRequest()
    req.open('GET', assetFile.assetUrl, true)
    req.responseType = 'blob'

    req.onload = () => {
      const blob = req.response // Note: not req.responseText
      if (blob){
        const file = new File([blob], 'CopyProof.docx', {
          type: blob.type,
        })

        saveAsset(serviceJob, file, fileName, assetFile, updateFn, createFn, updateAssetCallback)
      }
    }

    req.send(null)
  }
}
