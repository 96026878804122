const state = {
  id: null,
  name: '',
  entityType: 'serviceJob',
  accountManagers: [],
  campaigns: [],
  classifications: [],
  clients: [],
  customFilters: [],
  filterEnd: '',
  filterStart: '',
  search: '',
  serviceDateFilterEnd: '',
  serviceDateFilterStart: '',
  serviceJobStatuses: [],
  services: [],
  serviceUsers: [],
  tags: [],
  users: [],
}

export const requiredFields = [
  { key: 'name' },
]

export default state
