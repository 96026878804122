import { useContext } from 'react'

import { useThunkDispatch } from '@campaignhub/react-hooks'

import PageContext from '@contexts/pageContext'
import * as resourceActions from '@redux/modules/resource'

const createResourceDirect = (resourceParam, dispatch) => {
  const { createResourceDirect: createDirectFn } = resourceActions
  return dispatch(createDirectFn(resourceParam))
}

const deleteResource = (resourceParam, dispatch) => {
  const { deleteResource: deleteFn } = resourceActions

  return dispatch(deleteFn(resourceParam))
}

const loadResource = (resourceParam, options, dispatch) => {
  const { loadResource: loadFn } = resourceActions

  return dispatch(loadFn(resourceParam, options))
}

const scanFolder = (resourceParam, dispatch) => {
  const { scanFolder: scanFolderFn } = resourceActions

  return dispatch(scanFolderFn(resourceParam))
}

const launchResourceUploadModal = (dispatch, showResourceUploadModal) => new Promise((resolve, reject) => {
  if (showResourceUploadModal){
    const payload = {
      callbacks: {
      },
    }

    showResourceUploadModal(payload)
    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showResourceUploadModal not defined in PageContext callbacks'))
})

const useUploadResource = () => {
  const { callbacks } = useContext(PageContext)
  const { showResourceUploadModal } = callbacks || {}
  const dispatch = useThunkDispatch()

  return {
    callbacks: {
      createResourceDirect: resourceParam => createResourceDirect(resourceParam, dispatch),
      deleteResource: resourceParam => deleteResource(resourceParam, dispatch),
      launchResourceUploadModal: () => launchResourceUploadModal(dispatch, showResourceUploadModal),
      loadResource: (resourceParam, options) => loadResource(resourceParam, options, dispatch),
      scanFolder: resourceParam => scanFolder(resourceParam, dispatch),
    },
  }
}

export default useUploadResource
