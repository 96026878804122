import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import { toggleArray } from '@campaignhub/javascript-utils'
import {
  Box, Button, Checkbox, Image, ListItemWithImage, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import 'react-tippy/dist/tippy.css'
import { Tooltip } from 'react-tippy'

import PageContext from '@contexts/pageContext'
import Icons from '@components/Icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencil, faInfoCircle } from '@fortawesome/pro-light-svg-icons'
import useResource from '@hooks/useResource'

import customMetaData from '@functions/customMetaData'

const defaultState = {
  customMetaValues: {},
}

const ListView = (props) => {
  const { callbacks: { toggleUpdateSelectedIds }, editable, content, selected, selectedIds } = props
  const { file } = content

  const [state, setState] = useSetState(defaultState)
  const { customMetaValues } = state

  const { serviceJob, currentUser } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const selectContent = (selectedContent) => {
    const currentlySelected = toggleArray(
      selectedIds.includes(selectedContent.id) ? selectedIds : selectedIds.filter(id => id !== selectedContent.id),
      selectedContent.id,
    ) || []

    toggleUpdateSelectedIds(currentlySelected)
  }

  const { callbacks: { launchEditResourceDetailModal } } = useResource({ id: content.id })

  useEffect(() => {
    if (file?.downloadUrl){
      const xhr = new XMLHttpRequest()
      xhr.open('HEAD', file.downloadUrl)
      xhr.onreadystatechange = () => {
        if (xhr.readyState === 4 && xhr.status === 200){
          const meta = {}
          xhr.getAllResponseHeaders()
            .trim()
            .split(/[\r\n]+/)
            .map(value => value.split(/: /))
            .forEach((keyValue) => {
              if (keyValue[0].includes('x-ms-meta')){
                meta[keyValue[0].trim()] = keyValue[1].trim()
              }
            })
            setState({ customMetaValues: { ...meta } })
        }
      }
      xhr.send(null)
    }
  }, [file])

  return (
    <ListItemWithImage imageComponent={(
      <Box
        alignItems="center"
        as={content.previewUrl ? 'a' : null}
        borderRight={['none', '1px solid']}
        borderBottom={['1px solid', 'none']}
        borderColor={['lineColor', 'lineColor']}
        href={`${content.file?.downloadUrl || content.resourceUrl}`}
        justifyContent="center"
        padding="medium"
        style={{ textDecoration: 'none' }}
        width={['100%', 'fit-content']}
        target="_blank"
      >
        <Image
          boxProps={{ backgroundColor: 'hoverLightGrey' }}
          borderRadius={5}
          height={[180, '100%']}
          url={file.previewGeneratedAt ? content.previewUrl : ''}
          width={['100%', 200]}
        >
          {!file.previewGeneratedAt && (
            <Box
              alignItems="center"
              color="bodyFontColor"
              display="grid"
              justifyContent="center"
              width="100%"
            >
              <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={40} width="100%" />
            </Box>
          )}
        </Image>
      </Box>
    )}
    >
      <ListItemWithImage.Header>
        <ListItemWithImage.HeaderLeft>
          <ListItemWithImage.HeaderText>
            {file.displayName || file.originalName}
          </ListItemWithImage.HeaderText>
          <Text color="bodyFontLightColor" fontSize="xsmall">
            Date Uploaded: {DateTime.fromISO(file.createdAt, { zone: currentUser.timeZone }).toFormat('LL/dd/yyyy')}
          </Text>
        </ListItemWithImage.HeaderLeft>
        {editable && (
          <ListItemWithImage.HeaderRight>
            <Checkbox checked={selected} marginRight={0} onClick={() => selectContent(content)} />
          </ListItemWithImage.HeaderRight>
        )}
      </ListItemWithImage.Header>

      <ListItemWithImage.Footer>
        <ListItemWithImage.FooterLeft boxProps={{ maxWidth: '75%!important' }}>
          <Box fontSize="small" marginBottom="small" alignItems="center">
            <StatusBadge
              color="mysteryGrey"
              text={content.resourceType?.description}
              boxProps={{ marginRight: 'small' }}
            />
            <StatusBadge
              color="mysteryGrey"
              text={file.fileType.name}
              boxProps={{ marginRight: 'medium' }}
            />
            {Object.keys(customMetaValues).length ? (
              <Tooltip
                html={(
                  <div style={{ textAlign: 'left' }}>
                    {Object.entries(customMetaData).map(([key, value]) => (
                      Object.keys(customMetaValues).find(x => x.includes(key)) && (
                        <Text fontSize="small">
                          {value}: {key === 'datetimeoriginal'
                          ? DateTime.fromFormat(customMetaValues[Object.keys(customMetaValues)
                            .find(x => x.includes(key))], 'yyyy:MM:dd HH:mm:ss')
                            .toFormat('MM/dd/yy HH:mm')
                          : key === 'size' ? `${(customMetaValues[Object.keys(customMetaValues).find(x => x.includes(key))] / (1024 * 1024)).toFixed(2)} MB`
                          : customMetaValues[Object.keys(customMetaValues).find(x => x.includes(key))] }
                        </Text>
                      )
                    ))}
                  </div>
                )}
                trigger="mouseenter"
                arrow
                arrowSize="regular"
                duration={0}
              >
                <FontAwesomeIcon icon={faInfoCircle} color="black" size="md" style={{ verticalAlign: 'middle' }} />
              </Tooltip>
            ) : null}
          </Box>
          <Text color="bodyFontLightColor" fontSize="xsmall" variant="ellipsis" width={500}>
            {content.description || ''}
          </Text>
        </ListItemWithImage.FooterLeft>
        {editable && (
          <ListItemWithImage.FooterRight>
            <Button
              buttonStyle="secondaryEdit"
              icon={<FontAwesomeIcon icon={faPencil} />}
              onClick={launchEditResourceDetailModal}
              size="medium"
              height={37}
            >
              Edit
            </Button>
          </ListItemWithImage.FooterRight>
        )}
      </ListItemWithImage.Footer>
    </ListItemWithImage>
  )
}

ListView.propTypes = {
  callbacks: PropTypes.object,
  content: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  selected: PropTypes.bool,
  selectedIds: PropTypes.array,
}

export default ListView
