/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import useReduxAction from '@hooks/useReduxAction'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import { Box, FormField, Text } from '@campaignhub/suit-theme'

import custom from '@styles/custom.module.scss'

const defaultState = {
  clientState: [],
  showFilter: false,
  filterLabel: 'Clients',
  groupState: [{ name: 'All', isChecked: false }],
}

const ClientFilter = (props) => {
  const { setJobState, jobState } = props
  const { clientFilter } = jobState

  const [state, setState] = useSetState(defaultState)
  const { clientState, showFilter, filterLabel, groupState } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { clients } = entities

  const includes = ['clientType', 'accountManagers']
  const options = {
    includes: includes.join(','),
  }

  useReduxAction('clients', 'loadClients', options)

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  const groupClientByClientType = (array, key, item) => Object.values(array).reduce((result, currentValue) => {
      (result[currentValue[key][item]] = result[currentValue[key][item]] || []).push({
        ...currentValue,
        isChecked: clientFilter.includes(currentValue.id),
      })
      return result
    }, {})

  useEffect(() => {
    const groupedClientState = groupClientByClientType(clients, 'clientType', 'name')
    if (groupedClientState && Object.keys(groupedClientState).length !== 0){
      Object.keys(groupedClientState).forEach((group) => {
        groupedClientState[group] = groupedClientState[group].sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
        if (!groupState.some(x => x.name === group)){ setState({ groupState: [...groupState, { name: group, isChecked: false }] }) }
      })
    }
    setState({
      clientState: groupedClientState,
      filterLabel: clientFilter.length ? filterLabel : 'Clients',
    })
  }, [clients, clientFilter])

  const updateClientState = (checked, clientId) => {
    const updatedClientFilter = checked ? [...clientFilter, clientId] : clientFilter.filter(i => i !== clientId)
    setJobState({ clientFilter: updatedClientFilter })
    const initialUser = updatedClientFilter.length ? updatedClientFilter
      .map((id) => {
        const user = [].concat(...Object.values(clientState)).find(obj => obj.id === id)
        return user ? `${user.name}` : null
      })
      .find(fullName => fullName !== null) : 'Client'
    setState({ filterLabel: `${initialUser} ${updatedClientFilter.length > 1 ? '...' : ''}` })
  }

  const selectAll = (checked, type) => {
    const filterList = type === 'All' ? Object.values(clients) : clientState[type]
    setState({ filterLabel: (checked ? `All ${(type === 'All' ? 'Clients' : type)}` : 'Client') })
    setState({ groupState: groupState.map(group => (group.name === type ? { ...group, isChecked: checked } : group)) })
    let checklist = []

    if (checked){
      filterList.map((user) => {
        checklist = [...checklist, ...clientFilter, user.id]
      })
    } else {
      checklist = [...clientFilter]
      filterList.map((user) => {
        checklist = checklist.filter(i => i === user.id)
      })
    }

    setJobState({ clientFilter: [...new Set(checklist)] })
  }

  if (Object.keys(clientState).length === 0) return null

  return (
    <FormField direction="column" boxProps={{ paddingBottom: 'medium' }}>
      <Box className={custom.root} ref={contentEl}>
        <select
          onMouseDown={(e) => {
            e.preventDefault()
            setState({ showFilter: !showFilter })
          }}
          defaultValue="Clients"
        >
          <option>{ filterLabel }</option>
        </select>

        {showFilter && (
          <Box className={custom.popup}>
            <Box flexDirection="column" maxHeight="280px" overflowY="auto">
              <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                <input
                  type="checkbox"
                  className={custom.checkbox}
                  onChange={e => selectAll(e.target.checked, 'All')}
                  checked={groupState.find(group => group.name === 'All').isChecked}
                />
                <Text fontSize="small" marginLeft="medium">Select All</Text>
              </Box>
              {Object.keys(clientState).map(type => (
                <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                  <input
                    type="checkbox"
                    className={custom.checkbox}
                    onChange={e => selectAll(e.target.checked, type)}
                    checked={groupState.find(group => group.name === type).isChecked}
                  />
                  <Text fontSize="small" marginLeft="medium">Select All {type}</Text>
                </Box>
              ))}
              {Object.keys(clientState).map(clientGroup => (
                <>
                  <Text fontSize="small" fontWeight="medium" paddingBottom="medium" paddingTop="large">{clientGroup}</Text>
                  {clientState[clientGroup].map(client => (
                    <Box flexDirection="row" paddingTop="small" paddingBottom="medium" alignItems="center">
                      <input
                        className={custom.checkbox}
                        onChange={e => updateClientState(e.target.checked, client.id)}
                        type="checkbox"
                        checked={client.isChecked}
                      />
                      <Text fontSize="small" marginLeft="medium">{client.name}</Text>
                    </Box>
                  ))}
                </>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </FormField>
  )
}

ClientFilter.propTypes = {
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
}

export default ClientFilter
