import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import {
  Box, Button, FormField, LoadingBubbles, SidebarModal, FileUploadZone, Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import { getUppyOptions, getComponentCallbacks } from '@functions/uploadModal'

import useUppyUpload from '@hooks/useUppyUpload'
import useCurrentUser from '@hooks/useCurrentUser'
import useReduxAction from '@hooks/useReduxAction'

import userTypes from '@functions/userTypes'
import prestigeServices from '@functions/prestigeServices'

import FailedItemList from './components/FailedItemList'
import RecentlyUploadedList from './components/RecentlyUploadedList'
import ResourcePrompts from './components/ResourcePrompts'

const defaultState = {
  allowedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/DNG', 'image/CR2', 'image/ARW'],
  allowUpload: false,
  doneUpload: false,
  filesToUploadCount: 0,
  filteredFileTypes: [],
  selectedFileType: 'image',
  recentlyFailed: [],
  recentlyUploaded: [],
  lastDeletedId: 0,
  lastFailedBatch: [],
  lastUploadedBatch: [],
  selectedResourceType: 0,
  showUploadNewBatch: false,
}

const assetFileTypes = {
  image: {
    id: 'image',
    description: 'Image',
    fileTypes: ['image/jpeg', 'image/jpg'],
  },
  pdf: {
    id: 'pdf',
    description: 'PDF',
    fileTypes: ['application/pdf'],
  },
  video: {
    id: 'video',
    description: 'Video',
    fileTypes: ['video/mp4'],
  },
}

const insertRecentlyUploadedBatch = (newUploadList, uploadList, newFailedList, failedList, setState) => {
  setState({ lastUploadedBatch: [], lastFailedBatch: [], doneUpload: false })
  setState({
    recentlyUploaded: uploadList.concat(newUploadList),
    recentlyFailed: failedList.concat(newFailedList),
  })
}

const removeRecentlyDeleted = (id, list, setState) => {
  setState({ lastDeletedId: 0 })
  setState({ recentlyUploaded: list.filter(x => x.id !== id) })
}

const UploadModal = (props) => {
  const {
    callbacks,
    modalKey,
    serviceJob,
    showModal,
    title,
    titleSecondLine,
    serviceJobOptions,
    type,
    fileTypesAllowed,
    resourceTypes,
  } = props

  const { createDirectFn, deleteFn, checkProgress, checkUploadStatus, scanFolderFn } = callbacks

  const entities = useSelector(reduxState => reduxState.entities)
  const { assets, resources, serviceGroups, fileServiceContainers } = entities

  const { currentUser } = useCurrentUser()

  const [state, setState] = useSetState(defaultState)
  const {
    allowedFileTypes,
    allowUpload,
    doneUpload,
    filteredFileTypes,
    filesToUploadCount,
    recentlyFailed,
    recentlyUploaded,
    lastDeletedId,
    lastFailedBatch,
    lastUploadedBatch,
    selectedFileType,
    selectedResourceType,
    showUploadNewBatch,
  } = state

  const containerName = `service-jobs-${DateTime.fromISO(serviceJob.createdAt, { zone: 'utc' }).toFormat('yyyy')}`

  const { loading } = useReduxAction('fileServiceContainers', 'loadFileServiceContainer', containerName, [])
  const { loading: resourcesloading } = useSelector(reduxState => reduxState.resources)

  useReduxAction('serviceJobs', 'loadServiceJobV1', {
    includes: ['service', 'campaign', 'client', 'details', 'histories', 'postProductionChecklistItems', 'users', 'notes', 'tags'].join(','),
  }, [serviceJob.id, doneUpload], {
    dispatchAction: (action, requestOptions) => action(serviceJob.id, requestOptions),
    shouldPerformFn: () => doneUpload && type === 'Resources',
  })

  useEffect(() => {
    if (type === 'Assets') setState({ allowUpload: true })
  }, [type])

  useEffect(() => {
    if (lastDeletedId > 0){
      removeRecentlyDeleted(lastDeletedId, recentlyUploaded, setState)
    }
  }, [resources, assets])

  useEffect(() => {
    if (fileTypesAllowed){
      setState({ allowedFileTypes: fileTypesAllowed.split(',') })
    }
  }, [fileTypesAllowed])

  useEffect(() => {
    if (doneUpload){
      if (type === 'Resources'){
        setState({ allowUpload: false, showUploadNewBatch: true })
      }
      if (lastUploadedBatch.length > 0 || lastFailedBatch.length > 0){
        insertRecentlyUploadedBatch(lastUploadedBatch, recentlyUploaded, lastFailedBatch, recentlyFailed, setState)
      }
    }
  }, [doneUpload])

  const fileServiceContainer = Object.values(fileServiceContainers).find(x => x.container === containerName)
  const uppyCallbacks = { ...getComponentCallbacks('uppy', setState), createDirectFn, scanFolderFn }
  const uppyOptions = getUppyOptions(type, allowedFileTypes, serviceJobOptions, uppyCallbacks, selectedResourceType, filesToUploadCount, fileServiceContainer, currentUser.id)
  const uppy = useUppyUpload(uppyOptions)

  uppy.on('progress', (data) => {
    checkProgress(data)
  })

  useEffect(() => {
    checkUploadStatus(recentlyUploaded)
  }, [recentlyUploaded])

  const resetUploader = () => {
    setState({
      allowUpload: false,
      doneUpload: false,
      filesToUploadCount: 0,
      recentlyFailed: [],
      recentlyUploaded: [],
      lastDeletedId: 0,
      lastFailedBatch: [],
      lastUploadedBatch: [],
      selectedResourceType: resourceTypes ? Object.values(resourceTypes)[0]?.id : 0,
      showUploadNewBatch: false,
    })
  }

  const changeFileType = (event) => {
    setState({
      allowedFileTypes: assetFileTypes[event.target.value].fileTypes,
      selectedFileType: event.target.value,
    })
  }

  const changeResourceType = (event) => {
    setState({ selectedResourceType: event.target.value })
  }

  useEffect(() => {
    if (resourceTypes){
      if (prestigeServices.find(x => x.id === serviceJob.service?.id) && currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'Studio').id){
        setState({ selectedResourceType: resourceTypes.find(x => x.name === 'ClientGalleryImage').id })
      } else setState({ selectedResourceType: resourceTypes.find(x => x.name === 'PhotographyImage')?.id || Object.values(resourceTypes)[0]?.id })
    }
  }, [resourceTypes])

  useEffect(() => {
    if (type === 'Assets'){
      if (['Floorplan'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)){
        setState({ filteredFileTypes: Object.values(assetFileTypes).filter(fileType => fileType.id === 'pdf') })
      }
      if (['Photography', 'Virtual Staging'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)){
        setState({ filteredFileTypes: Object.values(assetFileTypes).filter(fileType => fileType.id === 'image') })
      }
      if (['Video', 'VidMo'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)){
        setState({ filteredFileTypes: Object.values(assetFileTypes).filter(fileType => fileType.id === 'video') })
      }
    }
  }, [serviceJob])

  useEffect(() => {
    if (filteredFileTypes.length){
      setState({
        allowedFileTypes: filteredFileTypes[0].fileTypes,
        selectedFileType: filteredFileTypes[0].id,
      })
    }
  }, [filteredFileTypes])

  return (
    <SidebarModal
      callbacks={callbacks}
      clickSafeZone
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title={title} titleSecondLine={titleSecondLine} />
      <SidebarModal.Content hasFooter={showUploadNewBatch}>
        <Box flexDirection="column" flexShrink={0}>
          {type === 'Resources' && (
            <ResourcePrompts
              callbacks={{
                allowResourceUpload: () => setState({ allowUpload: true }),
                updateUploadCount: x => setState({ filesToUploadCount: x }),
              }}
              uploadState={state}
            />
          )}
        </Box>

        {(loading || resourcesloading) && <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ flexShrink: 0 }} />}

        {!loading && allowUpload && (
          <Box flexDirection="column" marginBottom="large">
            {type === 'Assets' && (
              <FormField direction="column" label="Choose Type" marginTop="large" value={selectedFileType}>
                <select onChange={changeFileType}>
                  {Object.values(filteredFileTypes).map(fileType => <option value={fileType.id}>{fileType.description}</option>)}
                </select>
              </FormField>
            )}
            {type === 'Resources' && resourceTypes?.length > 0 && (
              <FormField direction="column" label="Choose Resource Type" marginTop="large" value={selectedResourceType}>
                <select onChange={changeResourceType} value={selectedResourceType}>
                  {Object.values(resourceTypes).map(({ id, description }) => <option value={id} key={id}>{description}</option>)}
                </select>
              </FormField>
            )}
            {uppy && ((type === 'Resources' && resourceTypes?.length > 0) || type === 'Assets') && (
              <FormField direction="column" marginTop="large">
                <FileUploadZone
                  uppy={uppy}
                  subTitle="Max Size is 2 GB"
                />
              </FormField>
            )}
            {!uppy && (
              <FormField direction="column" marginTop="large">
                <Text marginBottom="small" fontSize="xsmall">
                  Could not load uppy service
                </Text>
              </FormField>
            )}
          </Box>
        )}

        {recentlyFailed.length > 0 && (
          <SidebarModal.ExpandableSectionBox label={`Failed Items (${recentlyFailed.length})`}>
            <FailedItemList list={recentlyFailed} />
          </SidebarModal.ExpandableSectionBox>
        )}

        {recentlyUploaded.length > 0 && (
          <SidebarModal.ExpandableSectionBox label={`Uploaded Items (${recentlyUploaded.length})`}>
            <RecentlyUploadedList
              list={recentlyUploaded}
              callbacks={{ ...getComponentCallbacks('recentlyuploadedlist', setState), deleteFn }}
              fileType={type}
            />
          </SidebarModal.ExpandableSectionBox>
        )}
      </SidebarModal.Content>

      {showUploadNewBatch && (
        <SidebarModal.Footer>
          <Button
            buttonStyle="primaryCreate"
            onClick={() => resetUploader()}
            size="medium"
          >
            Upload New Batch
          </Button>
        </SidebarModal.Footer>
      )}
    </SidebarModal>
  )
}

UploadModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  serviceJobOptions: PropTypes.object.isRequired,
  type: PropTypes.string.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
  serviceJob: PropTypes.object,
  title: PropTypes.string,
  titleSecondLine: PropTypes.string,
  resourceTypes: PropTypes.array,
  fileTypesAllowed: PropTypes.string,
}

UploadModal.defaultProps = {
  modalKey: 'UploadModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <UploadModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
