/* eslint-disable no-param-reassign */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import swal from 'sweetalert2'
import { useSelector } from 'react-redux'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box,
  Button,
  FormField,
  ModalContext,
  SidebarModal,
  Text,
} from '@campaignhub/suit-theme'

import { useFilterForm } from '@hooks/useFilter'

const confirmDelete = (entityState, props, deleteFn) => {
  const {
    callbacks: { deleteFilter },
  } = props

  swal
    .fire({
      title: 'Remove View',
      text: 'Are you sure you want to remove this view?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
    })
    .then(({ value }) => {
      if (value){
        deleteFilter(entityState, deleteFn)
      }
    })
}

const updateServiceJobView = (entityState, props, updateFn) => {
  const {
    callbacks: { updateFilter },
    accountManagerFilter,
    classificationFilter,
    clientFilter,
    customFilter,
    dateFilter,
    filterTags,
    serviceDateFilter,
    serviceFilter,
    serviceUserFilter,
    statusFilter,
    userFilter,
  } = props

  if (dateFilter){
    entityState.filterEnd = dateFilter.filterEnd
    entityState.filterStart = dateFilter.filterStart
  }

  if (serviceDateFilter){
    entityState.serviceDateFilterEnd = serviceDateFilter.filterEnd
    entityState.serviceDateFilterStart = serviceDateFilter.filterStart
  }

  entityState.accountManagers = accountManagerFilter
  entityState.classifications = classificationFilter
  entityState.clients = clientFilter
  entityState.customFilters = customFilter
  entityState.serviceJobStatuses = statusFilter
  entityState.services = serviceFilter
  entityState.serviceUsers = serviceUserFilter
  entityState.tags = filterTags
  entityState.users = userFilter

  updateFilter(entityState, updateFn)
}

const EditServiceJobViewModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const filterPayload = digObject(modalData, modalKey, {})

  const {
    filter,
    callbacks: { deleteFilter: deleteFn, updateFilter: updateFn },
  } = filterPayload

  const filterFormPayload = useFilterForm(filter)

  const { deleting, updating } = useSelector(
    reduxState => reduxState.filters,
  )

  const {
    entityState,
    entityState: { name },
    errors,
    handlers,
    saveEnabled,
  } = filterFormPayload

  return (
    <SidebarModal
      callbacks={callbacks}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title="Edit View" />

      <SidebarModal.Content>
        <Box flexDirection="column" flexShrink={0} lineHeight="1.3">
          <FormField
            direction="column"
            marginTop="large"
            errorMessage={errors.name}
            label="* Name"
          >
            <input
              name="name"
              type="text"
              value={name}
              {...handlers}
              data-validate-field-on="change"
            />
          </FormField>
          <Text color="bodyFontLightColor" fontSize="small" marginTop="large">
            Note: Selected services and statuses shall update the current view
            on the dashboard list.
          </Text>
        </Box>
        <FormField direction="column" label="More Options" marginTop="large">
          <Button
            buttonStyle="ghostUtility"
            icon={<FontAwesomeIcon icon={faTrashAlt} />}
            loading={deleting}
            onClick={() => confirmDelete(entityState, props, deleteFn)}
            size="medium"
            style={{ width: '100%' }}
          >
            Remove View
          </Button>
        </FormField>
      </SidebarModal.Content>

      <SidebarModal.Footer>
        <Button
          buttonStyle="secondary"
          onClick={closeModal}
          size="medium"
          style={{ marginRight: 4, width: 'calc(50% - 4px)' }}
        >
          Cancel
        </Button>
        <Button
          buttonStyle="primaryCreate"
          disabled={!saveEnabled}
          loading={updating}
          onClick={() => updateServiceJobView(entityState, props, updateFn)}
          size="medium"
          style={{ marginLeft: 4, width: 'calc(50% - 4px)' }}
        >
          Update
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

EditServiceJobViewModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

EditServiceJobViewModal.defaultProps = {
  modalKey: 'EditServiceJobViewModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <EditServiceJobViewModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
