import React from 'react'
import PropTypes from 'prop-types'

import { Box, Text } from '@campaignhub/suit-theme'

import custom from '@styles/custom.module.scss'

import ListView from './components/ListView'

const ClientResources = (props) => {
  const { resources } = props

  return (
    <>
      {resources.length > 0 && (
        <Box flexDirection="column" fontSize="small" className={custom.scroll}>
          <Box flexDirection="column" padding="large" maxHeight="586px" overflowY="auto">
            {resources.map(item => (<ListView key={item.id} file={item} />))}
          </Box>
        </Box>
      )}

      {!resources.length && (
        <Box flexDirection="column" fontSize="small" padding="large">
          <Box
            alignItems="center"
            color="bodyFontColor"
            display="grid"
            height={[150, 250]}
            justifyContent="center"
          >
            <Text fontSize="small" fontWeight="600">
              No client resources available.
            </Text>
          </Box>
        </Box>
      )}
    </>
  )
}

ClientResources.propTypes = {
  resources: PropTypes.array,
}

export default ClientResources
