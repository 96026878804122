/* eslint-disable array-callback-return */
import React, { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import useUsers from '@hooks/useUsers'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import { Box, FormField, Text } from '@campaignhub/suit-theme'

import custom from '@styles/custom.module.scss'

import { groupBy } from '@functions/groupBy'

const defaultState = {
  filteredUsers: [],
  showFilter: false,
  userState: [],
  filterLabel: 'Account Manager',
  groupState: [{ name: 'All', isChecked: false }],
}

const AccountManagerFilter = (props) => {
  const { setJobState, jobState } = props
  const { accountManagerFilter } = jobState

  const [state, setState] = useSetState(defaultState)
  const { filteredUsers, showFilter, userState, filterLabel, groupState } = state

  const { users } = useUsers()

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  const entities = useSelector(reduxState => reduxState.entities)
  const { clients } = entities

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  useEffect(() => {
    const filteredAccountManager = Object.values(clients)?.reduce((filtered, client) => {
      if (client.accountManagers.length > 0 && Object.values(users).length > 0){
        client.accountManagers.forEach((x) => {
          if (filtered.filter(z => z.id === x.userId).length === 0){
            const user = Object.values(users).find(y => y.id === x.userId)
            filtered.push(user)
          }
        })
      }

      return filtered
    }, [])

    setState({
      filteredUsers: filteredAccountManager.map(user => ({
        ...user,
        isChecked: accountManagerFilter.includes(user?.id),
      })),
      filterLabel: accountManagerFilter.length ? filterLabel : 'Account Manager',
    })
  }, [users, accountManagerFilter])

  useEffect(() => {
    const groupedUserState = groupBy(filteredUsers, 'type')
    if (groupedUserState && Object.keys(groupedUserState).length !== 0){
      Object.keys(groupedUserState).forEach((group) => {
        groupedUserState[group] = groupedUserState[group]
        .sort((a, b) => ((`${a.firstName} ${a.lastName}`).toLowerCase() > (`${b.firstName} ${b.lastName}`).toLowerCase() ? 1 : -1))
      })
    }
    setState({ userState: groupedUserState })
  }, [filteredUsers])

  const updateUserState = (checked, userId) => {
    const updatedAccountManagerFilter = checked ? [...accountManagerFilter, userId] : accountManagerFilter.filter(i => i !== userId)
    setJobState({ accountManagerFilter: updatedAccountManagerFilter })
    const initialUser = updatedAccountManagerFilter.length ? updatedAccountManagerFilter
      .map((id) => {
        const user = [].concat(...Object.values(userState)).find(obj => obj.id === id)
        return user ? `${user.firstName} ${user.lastName}` : null
      })
      .find(fullName => fullName !== null) : 'Account Manager'
    setState({ filterLabel: `${initialUser} ${updatedAccountManagerFilter.length > 1 ? '...' : ''}` })
  }

  const selectAll = (checked, type) => {
    const filterList = type === 'All' ? filteredUsers : userState[type]
    setState({ filterLabel: (checked ? `All ${(type === 'All' ? 'Account Managers' : type)}` : 'Account Manager') })
    setState({ groupState: groupState.map(group => (group.name === type ? { ...group, isChecked: checked } : group)) })
    let checklist = []

    if (checked){
      filterList.map((user) => {
        checklist = [...checklist, ...accountManagerFilter, user?.id]
      })
    } else {
      checklist = [...accountManagerFilter]
      filterList.map((user) => {
        checklist = checklist.filter(i => i === user?.id)
      })
    }

    setJobState({ accountManagerFilter: [...new Set(checklist)] })
  }

  if (Object.keys(userState).length === 0) return null

  return (
    <FormField direction="column">
      <Box className={custom.root} ref={contentEl}>
        <select
          onMouseDown={(e) => {
            e.preventDefault()
            setState({ showFilter: !showFilter })
          }}
          defaultValue="Account Manager"
        >
          <option>{filterLabel}</option>
        </select>

        {showFilter && (
          <Box className={custom.popup}>
            <Box flexDirection="column" maxHeight="280px" overflowY="auto">
              <Box flexDirection="row" paddingBottom="large" alignItems="center">
                <input
                  type="checkbox"
                  className={custom.checkbox}
                  onChange={e => selectAll(e.target.checked, 'All')}
                  checked={groupState.find(group => group.name === 'All').isChecked}
                />
                <Text fontSize="small" marginLeft="medium">Select All</Text>
              </Box>
              {Object.keys(userState).map(userGroup => (
                <>
                  {userState[userGroup].map(user => (
                    <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                      <input
                        className={custom.checkbox}
                        onChange={e => updateUserState(e.target.checked, user.id)}
                        type="checkbox"
                        checked={user.isChecked}
                      />
                      <Text fontSize="small" marginLeft="medium">{`${user.firstName} ${user.lastName}`}</Text>
                    </Box>
                  ))}
                </>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </FormField>
  )
}

AccountManagerFilter.propTypes = {
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
}

export default AccountManagerFilter
