import React, { useContext } from 'react'
import { useSelector } from 'react-redux'

import { Box, Text } from '@campaignhub/suit-theme'
import PageContext from '@contexts/pageContext'

import AssetMarker from './components/AssetMarker'
import CopyTemplate from './components/CopyTemplate'
import VideoPlayer from './components/VideoPlayer'

const AssetSection = () => {
  const { serviceJob } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { assets, serviceGroups } = entities

  const componentMapping = {
    AssetMarker,
    CopyTemplate,
    VideoPlayer,
    DefaultView: () => (
      <Box
        alignItems="center"
        display="grid"
        height={[150, '90vh']}
        justifyContent="center"
        variant="white"
      >
        <Text fontSize="small" fontWeight="600">No Available View</Text>
      </Box>
    ),
  }

  const getAssetComponent = () => {
    switch (serviceGroups[serviceJob?.serviceGroupId]?.name){
      case 'Photography': case 'Floorplan': case 'Virtual Staging':
        return ({
          component: componentMapping.AssetMarker,
          content: [],
        })
      case 'Copywriting':
        return ({
          component: componentMapping.CopyTemplate,
          content: Object.values(assets).filter(asset => asset.serviceJobId === serviceJob.id && asset.copy?.id),
        })
      case 'Video': case 'VidMo':
        return ({
          component: componentMapping.VideoPlayer,
          content: [],
        })
      default: return ({ component: componentMapping.DefaultView })
    }
  }

  const { component: Component, content } = getAssetComponent()

  return <Component filteredContent={content} />
}

export default AssetSection
