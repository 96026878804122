/* eslint-disable no-console */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'

import swal from 'sweetalert2'
import JSZip from 'jszip'
import axios from 'axios'
import { saveAs } from 'file-saver'
import { pdf } from '@react-pdf/renderer'

import { Box, Button, Checkbox, Text, Tag } from '@campaignhub/suit-theme'

import { faSlidersH, faTrash, faFolderDownload, faFilePdf } from '@fortawesome/pro-light-svg-icons'
import { faCaretDown, faSpinner } from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useServiceJob from '@hooks/useServiceJob'
import useUploadResource from '@hooks/useUploadResource'

import custom from '@styles/custom.module.scss'

import Footer from './components/Footer'
import ImageListPDF from './components/ImageListPDF'
import ListView from './components/ListView'

const defaultState = {
  filteredResources: [],
  notificationsState: [],
  resourceState: {},
  resourceTypeFilter: [],
  pdfLoading: false,
  selectAll: false,
  showFilter: false,
  zipCount: 0,
  zipLoading: false,
}

const deleteSelectedResources = (resources, selectedIds, deleteFn) => {
  selectedIds.forEach((id) => {
    const resource = Object.values(resources).find(x => x.id === id)
    if (resource){
      deleteFn(resource).then(({ success, errors }) => {
        if (!success) toast.warning(errors[0])
      })
    }
  })
}

const Resources = (props) => {
  const { callbacks: { toggleUpdateSelectedIds, toggleCheckActionStatus }, editable, selectedIds, serviceJob, activeTabBar } = props

  const [state, setState] = useSetState(defaultState)
  const { resourceState, resourceTypeFilter, selectAll, showFilter, filteredResources, notificationsState, zipLoading, zipCount, pdfLoading } = state

  const entities = useSelector(reduxState => reduxState.entities)
  const { resources, resourceTypes } = entities
  const { result: notifications } = useSelector(reduxState => reduxState.notifications)
  const { deleting } = useSelector(reduxState => reduxState.resources)

  const { filteredServiceResourceTypes } = useServiceJob(serviceJob)
  const useUploadResourcePayload = useUploadResource()
  const {
    callbacks: {
      deleteResource: deleteFn,
    },
  } = useUploadResourcePayload

  const groupResourcesByResourceType = (array, key, item) => array.reduce((result, currentValue) => {
    (result[currentValue[key][item]] = result[currentValue[key][item]] || []).push({
      ...currentValue,
    })
    return result
  }, {})

  useEffect(() => {
    if (activeTabBar === 'resources'){
      setState({ filteredResources: Object.values(resources).filter(resource => parseInt(resource.serviceJobId, 10) === serviceJob.id) })
    } else {
      setState({ filteredResources: Object.values(resources).filter(resource => parseInt(resource.serviceJobId, 10) !== serviceJob.id
        && resource.campaignId === serviceJob.campaign.id) })
    }
  }, [activeTabBar, serviceJob])

  useEffect(() => {
    const contents = filteredResources.filter(resource => resourceTypeFilter.includes(resource.resourceTypeId) || !resourceTypeFilter.length)

    const groupByRelatedResourceTypeIds = filteredResources?.reduce((filtered, resource) => {
      if (!filtered.find(x => x === resource.resourceTypeId)){
        filtered.push(resource.resourceTypeId)
      }
      return filtered
    }, [])

    setState({ resourceState: groupResourcesByResourceType(contents, 'resourceType', 'description') })
      groupByRelatedResourceTypeIds.forEach(i => (filteredServiceResourceTypes.find(x => x.id === i) ? filteredServiceResourceTypes
      : filteredServiceResourceTypes.push(...Object.values(resourceTypes)?.filter(y => y.id === i))))
  }, [resourceTypeFilter, serviceJob, filteredResources])

  useEffect(() => {
    toggleUpdateSelectedIds(selectAll ? (
      filteredResources.filter(resource => resourceTypeFilter.includes(resource.resourceTypeId) || !resourceTypeFilter.length).map(content => content.id)
    ) : [])
  }, [selectAll])

  useEffect(() => {
    const newSelectedIds = []
    selectedIds.forEach((id) => {
      const resource = Object.values(resources).find(r => r.id === id)
      if (resource){
        newSelectedIds.push(id)
      }
    })
    if (newSelectedIds.length !== selectedIds.length){
      if (selectAll){
        setState({ selectAll: false })
      } else {
        toggleUpdateSelectedIds(newSelectedIds)
      }
    }
  }, [resources])

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  useEffect(() => {
    // TODO: based on how notifs are fired. we might need to get the length of file zip notifs only
    if (notificationsState.length > 0 && notifications.length > notificationsState.length){
      setState({ notificationsState: [] })
      toggleCheckActionStatus('zip-resources')
    }
  }, [notifications, notificationsState])

  const updateFilterState = (checked, resourceTypeId) => {
    const allResourceTypes = []
    Object.values(filteredServiceResourceTypes).map(x => allResourceTypes.push(x.id))

    if (checked){
      return setState({ resourceTypeFilter: resourceTypeId === 'All' ? allResourceTypes : [...resourceTypeFilter, resourceTypeId] })
    }

    return setState({ resourceTypeFilter: resourceTypeId === 'All' ? [] : resourceTypeFilter.filter(i => i !== resourceTypeId) })
  }

  const deleteResources = () => {
    swal.fire({
      title: 'Delete Selected Resources',
      html: `This action will delete the ${selectedIds.length} resources you have selected`
        + '<br/>Do you wish to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
    }).then(({ value }) => {
      if (value){
        deleteSelectedResources(resources, selectedIds, deleteFn)
        toggleCheckActionStatus('delete-resources')
      }
    })
  }

  const zip = new JSZip()
  const countRef = useRef()
  const totalRef = useRef()

  const download = item => axios.get(item.file.downloadUrl || item.resourceDownloadUrl, { responseType: 'blob',
    onDownloadProgress: (progressEvent) => {
      // indication if the zip has unexpectedly stopped
      console.info(progressEvent.loaded)
    } }).then((resp) => {
        countRef.current.textContent = Object.values(zip.files).length
        zip.file(item.file.originalName, resp.data)
    })
    .catch((err) => {
      console.error(err)
      console.error(item)
    })

  const downloadResources = (selectedResources) => {
    setState({ zipLoading: true, zipCount: selectedResources.length })
    const arrOfFiles = selectedResources.map(item => download(item))

    Promise.all(arrOfFiles)
      .then(() => {
        zip.generateAsync({ type: 'blob' }, (metadata) => {
          countRef.current.textContent = metadata.percent.toFixed(0)
          totalRef.current.textContent = '% Processing Zip'
          }).then((blob) => {
              saveAs(blob, `${serviceJob.campaign.name}.zip`)
              setState({ zipLoading: false })
          })
      })
      .catch((err) => {
        console.error(err)
        setState({ zipLoading: false })
      })
  }

  const generatePdf = async (selectedResources) => {
    setState({ pdfLoading: true })
    const blob = await pdf((
      <ImageListPDF resources={selectedResources} />
    )).toBlob()
    saveAs(blob, `${serviceJob.campaign.name}.pdf`)
    setState({ pdfLoading: false })
  }

  return (
    <>
      {filteredResources.length > 0 && (
        <Box flexDirection="column" fontSize="small" className={custom.scroll}>
          <Box
            alignItems="center"
            borderBottom="1px solid"
            borderColor="lineColor"
            flexDirection="row"
            padding="large"
            className={custom.root}
            ref={contentEl}
            style={{ justifyContent: 'space-between' }}
          >
            <Box width="auto" flexDirection="row" color="blue" onClick={() => setState({ showFilter: !showFilter })} style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon icon={faSlidersH} />
              <Text paddingX="medium" color="blue">Filter</Text>
              <FontAwesomeIcon icon={faCaretDown} />
            </Box>
            {editable && (
            <Box
              alignItems="center"
              flexShrink="0"
              width="auto"
              style={{ marginRight: 6 }}
            >
              <Button
                buttonStyle="secondary"
                disabled={selectedIds.length < 1}
                marginRight="medium"
                onClick={() => downloadResources(filteredResources.filter(x => selectedIds.includes(x.id)))}
                size="small"
                title="Download"
                width="auto"
              >
                {zipLoading && (
                  <>
                    <Text ref={countRef} marginRight="small"> 0 </Text>
                    <Text ref={totalRef} marginRight="large"> / {zipCount} </Text>
                  </>
                )}
                <FontAwesomeIcon icon={zipLoading ? faSpinner : faFolderDownload} className={zipLoading ? 'fa-spin' : ''} />
              </Button>
              <Button
                buttonStyle="secondary"
                disabled={selectedIds.length < 1}
                marginRight="medium"
                onClick={() => generatePdf(filteredResources.filter(x => selectedIds.includes(x.id)))}
                size="small"
                title="Download Proof Sheet"
                width="auto"
              >
                <FontAwesomeIcon icon={pdfLoading ? faSpinner : faFilePdf} className={pdfLoading ? 'fa-spin' : ''} />
              </Button>
              <Button
                buttonStyle="secondary"
                disabled={selectedIds.length < 1 || zipLoading || pdfLoading}
                marginRight="medium"
                onClick={() => deleteResources()}
                size="small"
                title="Delete Resources"
                width="auto"
              >
                <FontAwesomeIcon icon={deleting ? faSpinner : faTrash} className={deleting ? 'fa-spin' : ''} />
              </Button>
              <Tag boxProps={{ width: 'fit-content', fontSize: 'xsmall', marginRight: 'large' }}>{selectedIds.length} Selected</Tag>
              <Checkbox checked={selectAll} onClick={() => setState({ selectAll: !selectAll })} />
            </Box>
            )}
            {showFilter && (
              <Box className={custom.popup} width="300px">
                <Box flexDirection="column" maxHeight="280px" overflowY="auto">
                  <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                    <input type="checkbox" className={custom.checkbox} onChange={e => updateFilterState(e.target.checked, 'All')} />
                    <Text fontSize="small" marginLeft="medium">Select All</Text>
                  </Box>
                  {Object.values(filteredServiceResourceTypes).map(type => (
                    <Box flexDirection="row" key={type.id} paddingBottom="medium" alignItems="center">
                      <input
                        type="checkbox"
                        className={custom.checkbox}
                        onChange={e => updateFilterState(e.target.checked, type.id)}
                        checked={resourceTypeFilter.find(x => x === type.id)}
                      />
                      <Text fontSize="small" marginLeft="medium">{type.description}</Text>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}
          </Box>
          <Box flexDirection="column" padding="large" maxHeight="586px" overflowY="auto">
            {Object.keys(resourceState).map(resouceType => (
              <Box flexDirection="column" key={resouceType}>
                <Text fontSize="small" fontWeight={600} marginBottom="large">
                  {resouceType}
                </Text>
                {resourceState[resouceType].map((content) => {
                  const selected = selectedIds.includes(content.id)
                  return (
                    <ListView
                      callbacks={{ toggleUpdateSelectedIds, toggleCheckActionStatus }}
                      content={content}
                      editable={editable}
                      key={content.id}
                      selected={selected}
                      selectedIds={selectedIds}
                    />
                  )
                })}
              </Box>
            ))}
          </Box>
        </Box>
      )}

      {!filteredResources.length && (
        <Box flexDirection="column" fontSize="small" padding="large">
          <Box
            alignItems="center"
            color="bodyFontColor"
            display="grid"
            height={[150, 250]}
            justifyContent="center"
          >
            <Text fontSize="small" fontWeight="600">
              No resources uploaded yet.
            </Text>
          </Box>
        </Box>
      )}
      {editable && (
      <Footer
        isMissingResources={filteredResources.filter(item => item.resourceType.id === 26).length < serviceJob.totalAssets}
        callbacks={{ toggleCheckActionStatus: status => toggleCheckActionStatus(status) }}
        selectedIds={selectedIds}
      />
)}
    </>
  )
}

Resources.propTypes = {
  callbacks: PropTypes.object,
  editable: PropTypes.bool,
  selectedIds: PropTypes.array,
  serviceJob: PropTypes.object,
  activeTabBar: PropTypes.string,
}

export default Resources
