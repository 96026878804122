import { useSelector } from 'react-redux'

import useReduxAction from '@hooks/useReduxAction'

const useCtClientResources = (id) => {
  useReduxAction('ctClientResources', 'loadCtClientResources', id, [], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loaded, loading } = entityReducer
      return !loaded && !loading && !errors.length
    },
  })

  const entityReducer = useSelector(reduxState => reduxState.ctClientResources)
  const {
    errors,
    loaded,
    loading,
    result: ctClientResources,
  } = entityReducer

  return {
    ctClientResources,
    errors,
    loaded,
    loading,
  }
}

export default useCtClientResources
