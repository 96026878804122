/* eslint-disable consistent-return */
import { useEffect, useMemo } from 'react'
import { toast } from 'react-toastify'

import Uppy from '@uppy/core'
import XHRUpload from '@uppy/xhr-upload'
import ExifReader from 'exifreader'

import { storageAccountName } from '@functions/api'

const initializeUppy = (options) => {
  const {
    allowedFileTypes,
    callbacks,
    fileServiceContainer,
    maxFileSize,
    requestOptions = {},
    loadOptions,
  } = options

  const { sasToken, container, id: fileServiceContainerId } = fileServiceContainer || {}
  const { asset, asset: { file: { filePath }, version } } = requestOptions
  const { updateDirectFn } = callbacks

  const updateDirect = async (file) => {
    const { meta: { blobUrl, fileName } } = file

    const payload = {
      id: asset.id,
      description: 'new upload',
      fileServiceContainerId,
      filePath,
      fileName,
      blobUrl,
    }

    await updateDirectFn(payload, loadOptions).then(({ success, errors }) => {
      if (!success && errors){
        toast.warn(errors[0])
      }
    })
  }

  const uppy = new Uppy({
    id: 'uppyUpload',
    debug: false,
    autoProceed: false,
    restrictions: {
      allowedFileTypes,
      maxFileSize,
      maxNumberOfFiles: 1,
    },
  })
    .use(XHRUpload, {
      method: 'PUT',
      formData: false,
      fieldName: 'FormFile',
      getResponseError(){ },
      timeout: 0,
    })
    .on('restriction-failed', (file, error) => {
      toast.warning(`${error}.  File: ${file.name}`)
    })
    .on('file-added', async (file) => {
      const fileName = file.meta.name.replace(/\s+/g, ' ').replace(/(\.[^.]+$)/, `_v${version + 1}$1`)
      const blobUrl = encodeURI(`https://${storageAccountName}.blob.core.windows.net/${container}/${filePath}/${fileName}`)

      uppy.setFileMeta(file.id, {
        ...requestOptions,
        fileServiceContainerId,
        filePath,
        fileName,
        blobUrl,
      })

      if (file.meta.type.startsWith('image/')){
        const arrayBuffer = await file.data.arrayBuffer()

        try {
          const metaKeys = [
            'ColorSpace',
            'DateTimeOriginal',
            'FNumber',
            'ExposureTime',
            'ImageLength',
            'ImageWidth',
            'ISOSpeedRatings',
            'Make',
            'Model',
            // jpg exif keys has spaces in between
            'Color Space',
            'Image Height',
            'Image Width',
          ]

          const exifTags = ExifReader.load(arrayBuffer)
          const filterExif = Object.fromEntries(
            metaKeys.map(key => [key, exifTags[key]]),
          )

          uppy.setFileState(file.id, {
            xhrUpload: {
              ...file.xhrUpload,
              endpoint: `${blobUrl}?${sasToken}`,
              headers: {
                'x-ms-blob-type': 'BlockBlob',
                'x-ms-blob-content-type': file.meta.type,
                'x-ms-meta-size': file.size,
                ...(filterExif?.DateTimeOriginal) && { 'x-ms-meta-datetimeoriginal': filterExif.DateTimeOriginal.description },
                ...(filterExif?.ExposureTime) && { 'x-ms-meta-exposuretime': `ISO ${filterExif.ExposureTime.description}` },
                ...(filterExif?.FNumber) && { 'x-ms-meta-fnumber': filterExif.FNumber.description },
                ...(filterExif?.ISOSpeedRatings) && { 'x-ms-meta-iso': filterExif.ISOSpeedRatings.description },
                ...(filterExif?.Make) && { 'x-ms-meta-make': filterExif.Make.description },
                ...(filterExif?.Model) && { 'x-ms-meta-model': filterExif.Model.description },
                ...(filterExif?.ColorSpace && !filterExif['Color Space']?.value) && { 'x-ms-meta-colorspace': filterExif.ColorSpace.description },
                ...(filterExif['Color Space']?.value) && { 'x-ms-meta-colorspace': filterExif['Color Space'].value },
                ...(filterExif['Image Height']?.value && filterExif['Image Width']?.value)
                  && { 'x-ms-meta-dimensions': `${filterExif['Image Width'].value} x ${filterExif['Image Height'].value}` },
                ...(filterExif?.ImageLength && filterExif?.ImageWidth && (!filterExif['Image Height']?.value && !filterExif['Image Width']?.value))
                  && { 'x-ms-meta-dimensions': `${filterExif.ImageWidth.value} x ${filterExif.ImageLength.value}` },
              },
            },
          })
          uppy.upload()
        } catch (error){
          console.error('Error parsing EXIF data:', error)

          uppy.setFileState(file.id, {
            xhrUpload: {
              ...file.xhrUpload,
              endpoint: `${blobUrl}?${sasToken}`,
              headers: {
                'x-ms-blob-type': 'BlockBlob',
                'x-ms-blob-content-type': file.meta.type,
              },
            },
          })
          uppy.upload()
        }
      } else {
        uppy.setFileState(file.id, {
          xhrUpload: {
            ...file.xhrUpload,
            endpoint: `${blobUrl}?${sasToken}`,
            headers: {
              'x-ms-blob-type': 'BlockBlob',
              'x-ms-blob-content-type': file.meta.type,
            },
          },
        })

        uppy.upload()
      }
    })
    .on('upload-error', (file) => {
      toast.warning(`Failed to upload ${file.name}`)
    })
    .on('complete', async (files) => {
      if (files.successful.length) await updateDirect(files.successful[0])
      uppy.reset()
    })

  return uppy
}

const checkRequiredOptions = (options = {}) => {
  const requiredOptions = ['allowedFileTypes', 'requestOptions', 'fileServiceContainer']

  return requiredOptions.every(key => Object.keys(options).includes(key))
}

function useUppyUploadVersion(options = {}){
  const uppy = useMemo(() => {
    if (checkRequiredOptions(options)){
      return initializeUppy(options)
    }
  })

  useEffect(() => () => (uppy ? uppy.close() : null))

  return uppy
}

export default useUppyUploadVersion
