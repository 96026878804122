import React from 'react'
import PropTypes from 'prop-types'

import {
  Box,
  FormField,
  DashboardModule
 } from '@campaignhub/suit-theme'

 import useClientsV3 from '@hooks/useClientsV3'
 import useReduxAction from '@hooks/useReduxAction'

const AgentDetail = (props) => {
  const { userForm, customErrors } = props
  const {
    entityState: {
      agent
    },
    handlers,
    setEntityState,
  } = userForm


  const options = {
    size: -1,
    orderBy: '{ \'name\': \'asc\'}',
  }

  useReduxAction('clientsV3', 'loadClientsV3', options)

  const { clientsV3 } = useClientsV3({
    searchFilter : '',
  })

  return (
    <DashboardModule title="Agent Information">
      <Box flexDirection="column" padding="large">
        <Box
          flexDirection={['column', 'row']}
          marginBottom={[0, 'large']}
          width={['100%', 'calc(50% - 4px)']}
        >
          <FormField
            boxProps={{ flex: 1 }}
            label="Office"
            errorMessage={customErrors['agent.office']}
          >
            <select
              data-validate-field-on="change"
              value={agent.office}
              onChange={(event) => {
                agent.office = event.target.value
                setEntityState({ agent })
              }}
              name="agent.office"
              {...handlers}
            >
              <option value="">Please Select...</option>
              {Object.values(clientsV3).map(client => (
                <option value={client.id} key={client.id}>
                  {client.name} ({client.number})
                </option>
              ))}
            </select>
          </FormField>
        </Box>
      </Box>
    </DashboardModule>
    )
 }
 AgentDetail.propTypes = {
  userForm: PropTypes.object,
  customErrors: PropTypes.array,
  callbacks: PropTypes.object,
}

  export default AgentDetail
