import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import { Box, StatusBadge, Tag, Text } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'

import Icons from '@components/Icons'
import styles from '@styles/custom.module.scss'

const defaultState = {
  expanded: false,
  statusState: [],
}

const ServiceStatusFilter = (props) => {
  const { setJobState, jobState } = props
  const { statusFilter } = jobState

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses } = entities

  const [state, setState] = useSetState(defaultState)
  const { expanded, statusState } = state

  useEffect(() => {
    let statusList = Object.values(serviceJobStatuses).map(status => ({ ...status, isChecked: statusFilter.includes(status.id) }))
    if (statusList.length){
      statusList = [
        {
          id: 'all',
          description: 'Select All',
          isChecked: Object.values(serviceJobStatuses).length === statusFilter.length,
          name: 'Select All',
        },
        ...statusList,
      ]
    }
    setState({ statusState: statusList })
  }, [serviceJobStatuses, statusFilter])

  const updateFilterState = (checked, statusId) => {
    if (statusId === 'all'){
      setState({
        statusState:
          statusState.map((data) => {
            const temp = data
            temp.isChecked = checked
            return temp
          }),
      })
      if (checked){
        const statusStateIds = statusState.filter(x => x.id !== 'all').map(x => x.id)
        setJobState({ statusFilter: [...statusStateIds] })
      } else {
        setJobState({ statusFilter: [] })
      }
    } else {
      setState({
        statusState:
          statusState.map((data) => {
            const temp = data
            if (statusId === temp.id){
              temp.isChecked = checked
              if (checked){ setJobState({ statusFilter: [...statusFilter, statusId] }) } else {
                setJobState({ statusFilter: statusFilter.filter(i => i !== temp.id) })
              }
            }
            return temp
          }),
      })
    }
  }

  return (
    <Box flexDirection="column">
      <Box
        flexDirection="row"
        marginBottom={expanded ? 'large' : null}
        onClick={() => setState({ expanded: !expanded })}
        style={{ cursor: 'pointer' }}
      >
        <Box color="bodyFontLightColor" width="auto" alignItems="center">
          <Icons name="Status" />
          <Text color="bodyFontLightColor" fontSize="small" fontAlign="center" marginLeft="small">
            Status
          </Text>
        </Box>
        <Box color="bodyFontLightColor" display="flex" justifyContent="flex-end" alignItems="center">
          <Tag
            boxProps={{
                width: 'fit-content',
                fontSize: 'xsmall',
                marginRight: 'medium',
              }}
          >
            Selected:&nbsp;
            {statusFilter?.map((x) => {
              const status = Object.values(serviceJobStatuses).find(s => s.id === x)
              return status?.jobCount || 0
            })?.reduce((sum, a) => sum + a, 0)}
          </Tag>
          <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} />
        </Box>
      </Box>
      {expanded && (
        <Box flexDirection="column" alignItems="center" lineHeight="1.5">
          {statusState.map(status => (
            <Box key={status.id} flexDirection="column" alignItems="center" lineHeight="1.4" marginBottom="medium">
              <Box flexDirection="row">
                <Box color="bodyFontLightColor" marginLeft="auto" marginRight="medium" width="auto">
                  <input
                    onChange={e => updateFilterState(e.target.checked, status.id)}
                    type="checkbox"
                    checked={status.isChecked}
                    className={styles.checkbox}
                  />
                </Box>
                <Box flexDirection="column">
                  <Text color="bodyFontLightColor" fontSize="small" marginRight="medium">
                    {status.description}
                  </Text>
                </Box>
                {status.id !== 'all' && (
                  <Box flexDirection="column" width="auto">
                    <StatusBadge color="gray" text={status.jobCount ?? 0} />
                  </Box>
                )}
              </Box>
            </Box>
            ))}
        </Box>
      )}
    </Box>
  )
}

ServiceStatusFilter.propTypes = {
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
}

export default ServiceStatusFilter
