import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { DateTime } from 'luxon'

import {
  Box, FormField, LoadingModule, SidebarModal, FileUploadZone, Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'

import { getUppyOptions, getComponentCallbacks } from '@functions/uploadModal'

import useUppyUploadVersion from '@hooks/useUppyUploadVersion'
import useReduxAction from '@hooks/useReduxAction'

import VersionList from './components/VersionList'

const defaultState = {
  allowedFileTypes: ['image/jpeg', 'image/jpg', 'image/png', 'image/DNG', 'image/CR2', 'image/ARW'],
  filteredFileTypes: [],
  selectedFileType: 'image',
}

const assetFileTypes = {
  image: {
    id: 'image',
    description: 'Image',
    fileTypes: ['image/jpeg', 'image/jpg'],
  },
  pdf: {
    id: 'pdf',
    description: 'PDF',
    fileTypes: ['application/pdf'],
  },
  video: {
    id: 'video',
    description: 'Video',
    fileTypes: ['video/mp4'],
  },
}

const UploadVersionModal = (props) => {
  const {
    callbacks, modalKey, serviceJob, showModal, title, titleSecondLine,
    serviceJobOptions, type, displayAllowedFileTypes,
  } = props

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups, fileServiceContainers } = entities

  const { updateDirectFn } = callbacks

  const [state, setState] = useSetState(defaultState)
  const { allowedFileTypes, filteredFileTypes, selectedFileType } = state

  const containerName = `service-jobs-${DateTime.fromISO(serviceJob.createdAt, { zone: 'utc' }).toFormat('yyyy')}`

  const { loading } = useReduxAction('fileServiceContainers', 'loadFileServiceContainer', containerName, [serviceJob])

  const fileServiceContainer = Object.values(fileServiceContainers).find(x => x.container === containerName)
  const uppyCallbacks = { ...getComponentCallbacks('uppy', setState), updateDirectFn }
  const uppyOptions = getUppyOptions(type, allowedFileTypes, serviceJobOptions, uppyCallbacks, 0, 0, fileServiceContainer)
  const uppy = useUppyUploadVersion(uppyOptions)

  const changeFileType = (event) => {
    setState({
      allowedFileTypes: assetFileTypes[event.target.value].fileTypes,
      selectedFileType: event.target.value,
    })
  }

  useEffect(() => {
    if (type === 'Assets'){
      if (['Floorplan'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)){
        setState({ filteredFileTypes: Object.values(assetFileTypes).filter(fileType => fileType.id === 'pdf') })
      }
      if (['Photography', 'Virtual Staging'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)){
        setState({ filteredFileTypes: Object.values(assetFileTypes).filter(fileType => fileType.id === 'image') })
      }
      if (['Video', 'VidMo'].includes(serviceGroups[serviceJob.serviceGroupId]?.name)){
        setState({ filteredFileTypes: Object.values(assetFileTypes).filter(fileType => fileType.id === 'video') })
      }
    }
  }, [serviceJob])

  useEffect(() => {
    if (filteredFileTypes.length){
      setState({
        allowedFileTypes: filteredFileTypes[0].fileTypes,
        selectedFileType: filteredFileTypes[0].id,
      })
    }
  }, [filteredFileTypes])

  return (
    <SidebarModal
      callbacks={callbacks}
      modalKey={modalKey}
      showModal={showModal}
      size="small"
    >
      <SidebarModal.Header callbacks={callbacks} title={title} titleSecondLine={titleSecondLine} />

      <SidebarModal.Content hasFooter={false}>
        <Box flexDirection="column" marginBottom="large">
          {displayAllowedFileTypes && (
            <FormField direction="column" label="Choose Type" marginTop="large" value={selectedFileType}>
              <select onChange={changeFileType}>
                {Object.values(filteredFileTypes).map(fileType => (
                  <option key={fileType.id} value={fileType.id}>{fileType.description}</option>
                ))}
              </select>
            </FormField>
          )}

          <LoadingModule loading={loading} boxProps={{ border: 0 }} />

          {!loading && uppy && filteredFileTypes.length && (
            <FormField direction="column" marginTop="large">
              <FileUploadZone
                uppy={uppy}
                subTitle="Max Size is 2 GB"
              />
            </FormField>
          )}
          {!uppy && (
            <FormField direction="column" marginTop="large">
              <Text marginBottom="small" fontSize="xsmall">
                Could not load uppy service
              </Text>
            </FormField>
          )}
        </Box>
        <Text marginBottom="large" fontSize="small" fontWeight="500">
          Versions
        </Text>
        <VersionList />
      </SidebarModal.Content>
    </SidebarModal>
  )
}

UploadVersionModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  displayAllowedFileTypes: PropTypes.bool.isRequired,
  modalKey: PropTypes.string,
  serviceJob: PropTypes.object,
  serviceJobOptions: PropTypes.object.isRequired,
  showModal: PropTypes.bool,
  title: PropTypes.string,
  titleSecondLine: PropTypes.string,
  type: PropTypes.string.isRequired,
}

UploadVersionModal.defaultProps = {
  modalKey: 'UploadVersionModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <UploadVersionModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
