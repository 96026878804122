/* eslint-disable array-callback-return */
/* eslint-disable no-param-reassign */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import { Box, FormField, Text } from '@campaignhub/suit-theme'

import custom from '@styles/custom.module.scss'

import classifications from '@functions/classifications'

const defaultState = {
  classificationState: [],
  showFilter: false,
  filterLabel: 'Client Classification',
  groupState: [{ name: 'All', isChecked: false }],
}

const ClassificationFilter = (props) => {
  const { setJobState, jobState } = props
  const { classificationFilter } = jobState

  const [state, setState] = useSetState(defaultState)
  const { classificationState, showFilter, filterLabel, groupState } = state

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showFilter })

  useEffect(() => {
    if (isClickedOutside && showFilter){
      setState({ showFilter: !showFilter })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  useEffect(() => {
    const filteredClassification = Object.values(classifications)
    setState({
      classificationState: filteredClassification.map(classification => ({
        ...classification,
        isChecked: classificationFilter.includes(classification.name),
      })),
      filterLabel: classificationFilter.length ? filterLabel : 'Client Classification',
    })
  }, [classifications, classificationFilter])

  const updateClassificationState = (checked, classificationName) => {
    const updatedClassificationFilter = checked ? [...classificationFilter, classificationName] : classificationFilter.filter(i => i !== classificationName)
    setJobState({ classificationFilter: updatedClassificationFilter })
    const initialClassification = updatedClassificationFilter.length ? updatedClassificationFilter
      .map((name) => {
        const classification = classificationState.find(obj => obj.name === name)
        return classification ? `${classification.name}` : null
      })
      .find(fullName => fullName !== null) : 'Client Classification'
    setState({ filterLabel: `${initialClassification} ${updatedClassificationFilter.length > 1 ? '...' : ''}` })
  }

  const selectAll = (checked, type) => {
    const filterList = type === 'All' ? Object.values(classifications) : classificationState[type]
    setState({ filterLabel: (checked ? `All ${(type === 'All' ? 'Client Classifications' : type)}` : 'Client Classification') })
    setState({ groupState: groupState.map(group => (group.name === type ? { ...group, isChecked: checked } : group)) })
    let checklist = []

    if (checked){
      filterList.map((classification) => {
        checklist = [...checklist, ...classificationFilter, classification.name]
      })
    } else {
      checklist = [...classificationFilter]
      filterList.map((classification) => {
        checklist = checklist.filter(i => i === classification.name)
      })
    }

    setJobState({ classificationFilter: [...new Set(checklist)] })
  }

  if (Object.keys(classificationState).length === 0) return null

  return (
    <FormField direction="column" boxProps={{ paddingBottom: 'medium' }}>
      <Box className={custom.root} ref={contentEl}>
        <select
          onMouseDown={(e) => {
            e.preventDefault()
            setState({ showFilter: !showFilter })
          }}
          value="Client Classification"
        >
          <option>{filterLabel}</option>
        </select>
        {showFilter && (
          <Box className={custom.popup}>
            <Box flexDirection="column" maxHeight="280px" overflowY="auto">
              <Box flexDirection="row" paddingBottom="large" alignItems="center">
                <input
                  type="checkbox"
                  className={custom.checkbox}
                  onChange={e => selectAll(e.target.checked, 'All')}
                  checked={groupState.find(group => group.name === 'All').isChecked}
                />
                <Text fontSize="small" marginLeft="medium">Select All</Text>
              </Box>
              {Object.values(classificationState).map(classification => (
                <>
                  <Box flexDirection="row" paddingBottom="medium" alignItems="center">
                    <input
                      className={custom.checkbox}
                      onChange={e => updateClassificationState(e.target.checked, classification.name)}
                      type="checkbox"
                      checked={classification.isChecked}
                    />
                    <Text fontSize="small" marginLeft="medium">{classification.name}</Text>
                  </Box>
                </>
                ))}
            </Box>
          </Box>
        )}
      </Box>
    </FormField>
  )
}

ClassificationFilter.propTypes = {
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
}

export default ClassificationFilter
