/* eslint-disable import/prefer-default-export */
export function getServiceStatusColor(status){
  switch (status){
    case 'Ordered': return '#084DA3'
    case 'AwaitingSitePlan': return '#2771CC'
    case 'SitePlanAvailable': return '#2771CC'
    case 'AwaitingBooking': return '#3584E6'
    case 'Booked': return '#4A93ED'
    case 'Uploaded': return '#64A7FA'
    case 'AwaitingSelection': return '#D64B0A'
    case 'AwaitingAssignment': return '#F45C15'
    case 'Assigned': return '#F56929'
    case 'InProduction': return '#F6773C'
    case 'AwaitingQC': return '#F58E11'
    case 'InQC1': return '#FF9E00'
    case 'InQC': return '#93550A'
    case 'SentForVerification': return '#FCD12A'
    case 'RejectToStudio': return '#BD1923'
    case 'InRejection': return '#D11925'
    case 'SentToClient': return '#24BCA1'
    case 'ChangeRequest': return '#D11925'
    case 'Approved': return '#0E957C'
    case 'Archived': return '#0A705D'
    default: return '#000'
  }
}
