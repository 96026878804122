import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { digObject } from '@campaignhub/javascript-utils'
import {
  Box, Button, Image, Link, ModalContext, SidebarModal, Text,
} from '@campaignhub/suit-theme'

import Icons from '@components/Icons'
import PageContext from '@contexts/pageContext'

const ClientResourceModal = (props) => {
  const { callbacks, modalKey, showModal } = props

  const { serviceJob } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const { closeModal } = callbacks

  const modalContext = useContext(ModalContext)
  const { modalData } = modalContext

  const resourcePayload = digObject(modalData, modalKey, {})

  const { resource } = resourcePayload

  return (
    <SidebarModal callbacks={callbacks} modalKey={modalKey} showModal={showModal} size="small">
      <SidebarModal.Header
        callbacks={callbacks}
        title="Client"
        titleSecondLine="Resource"
      />
      <SidebarModal.Content>
        <Box flexDirection="column" marginBottom="small" borderRadius={5}>
          <Link href={`${resource?.download_uri}`} target="_blank">
            <Image
              backgroundPosition="center top"
              borderRadius={5}
              boxProps={{ backgroundColor: 'hoverLightGrey' }}
              width="100%"
              height={230}
              url={resource?.download_uri || ''}
            >
              {resource?.content_type === 'application/pdf' && (
                <Box
                  alignItems="center"
                  color="bodyFontColor"
                  display="grid"
                  justifyContent="center"
                  width="100%"
                >
                  <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={40} width="100%" />
                </Box>
              )}
            </Image>
          </Link>
        </Box>
        <Text color="bodyFontLightColor" fontSize="xsmall" textAlign="center" style={{ width: '100%', wordWrap: 'break-word', overflowWrap: 'break-word' }}>
          {resource?.metadata?.name || resource.name}
        </Text>
        <Text marginBottom="medium" fontSize="small" marginTop="large">File Note:</Text>
        <Text color="bodyFontLightColor" marginBottom="small" fontSize="small">
          {resource?.metadata?.notes}
        </Text>
      </SidebarModal.Content>
      <SidebarModal.Footer>
        <Button
          buttonStyle="secondaryUtility"
          onClick={closeModal}
          size="medium"
        >
          Close
        </Button>
      </SidebarModal.Footer>
    </SidebarModal>
  )
}

ClientResourceModal.propTypes = {
  callbacks: PropTypes.object.isRequired,
  modalKey: PropTypes.string,
  showModal: PropTypes.bool,
}

ClientResourceModal.defaultProps = {
  modalKey: 'ClientResourceModal',
}

const LazyLoadedModal = props => (
  <SidebarModal.RenderController {...props}>
    <ClientResourceModal {...props} />
  </SidebarModal.RenderController>
)

export default LazyLoadedModal
