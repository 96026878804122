import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import { Duration, DateTime } from 'luxon'
import swal from 'sweetalert2'

import {
  Box, ListItem, Text,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useServiceJobUser from '@hooks/useServiceJobUser'
import useServiceJobTimeTracker from '@hooks/useServiceJobTimeTracker'
import useTimeTracker from '@hooks/useTimeTracker'
import useReduxAction from '@hooks/useReduxAction'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faPlay, faSpinner } from '@fortawesome/pro-solid-svg-icons'

import { useSetState } from '@campaignhub/react-hooks'

import IconProfile from '@components/IconProfile'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const defaultState = {
  clockStart: false,
  userTotalTime: 0,
  inProgressJob: null,
}

const deleteServiceJobUser = (serviceJobUser, deleteFn) => {
  swal.fire({
    title: 'Remove Assigned User',
    text: 'Are you sure you want remove this user?',
    icon: 'warning',
    showCancelButton: true,
    confirmButtonText: 'Yes',
    confirmButtonColor: '#e2001a',
    showClass: 'slide-from-top',
  }).then(({ value }) => {
    if (value){
      deleteFn(serviceJobUser).then(({ success, errors }) => {
        if (!success && errors){
          toast.warning(errors[0])
        }
      })
    }
  })
}

const AssignedUser = (props) => {
  const { serviceJobUser, showDetails, serviceJob } = props

  const [state, setState] = useSetState(defaultState)
  const { clockStart, userTotalTime, inProgressJob } = state
  const [timerSeconds, setTimerSeconds] = useState(0)

  const payload = {
    filterStart: '-7d',
    filterEnd: '1d',
    includes: 'serviceJobUser',
  }
  useReduxAction('serviceJobsTimeTrackers', 'getServiceJobUserTime', {
    id: serviceJob.id,
    payload,
  }, [clockStart, inProgressJob], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loading: isLoading } = entityReducer
      return !isLoading && !errors.length && (!clockStart || !inProgressJob)
    },
  })

  const entityReducer = useSelector(reduxState => reduxState.serviceJobsTimeTrackers)
  const { loading, creating, result: serviceJobsTimeTrackers } = entityReducer

  const entities = useSelector(reduxState => reduxState.entities)

  const { serviceJobUserTimes } = entities

  const {
    callbacks: {
      deleteServiceJobUser: deleteFn,
    },
  } = useServiceJobUser(serviceJobUser)

  const {
    callbacks: {
      jobTimeStart: jobTimeStartFn,
      jobTimeStop: jobTimeStopFn,
    },
  } = useServiceJobTimeTracker()

  const { callbacks: { getUserTime: getUserTimeFn } } = useTimeTracker()
  const { timeZone } = useCurrentUser()

  useEffect(() => {
    const interval = setInterval(() => {
      if (inProgressJob){
        setTimerSeconds(seconds => seconds + 1000)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [inProgressJob])

  useEffect(() => {
    if (serviceJobsTimeTrackers){
      const lastJob = Object.values(serviceJobsTimeTrackers).pop()

      if (serviceJobsTimeTrackers.length > 0 && serviceJobsTimeTrackers[0].serviceJobUser && serviceJobUser){
        const filteredTimeTrackers = serviceJobsTimeTrackers.filter(x => x.serviceJobUser.serviceJobUserRoleId === serviceJobUser.serviceJobUserRole.id)
        if (filteredTimeTrackers[0]?.serviceJobUser.serviceJobUserRoleId === serviceJobUser.serviceJobUserRole.id){
          setState({ userTotalTime: filteredTimeTrackers.map(x => x.totalClockTime).reduce((a, b) => a + b) })
        }
      }

      if (inProgressJob === null){
        setTimerSeconds(userTotalTime)
      } else if (inProgressJob.serviceJobUserRoleId === serviceJobUser.serviceJobUserRole.id && userTotalTime === 0){
        const filteredTimeTrackers = serviceJobsTimeTrackers.filter(x => x.serviceJobUser.serviceJobUserRoleId === inProgressJob.serviceJobUserRoleId)
        const currentDate = DateTime.fromISO(DateTime.now(), { zone: timeZone })
        const currentStart = DateTime.fromISO(inProgressJob?.jobStart, { zone: timeZone })
        const diff = currentDate.diff(currentStart)
        const totalTime = filteredTimeTrackers.map(x => x.totalClockTime).reduce((a, b) => a + b, 0)
        setTimerSeconds(totalTime + diff.toObject().milliseconds)
      }

      if (lastJob && !lastJob?.jobEnd){
        setState({
          clockStart: !lastJob?.jobEnd,
        })
      } else if (lastJob && lastJob.jobEnd){
        setState({
          clockStart: false,
        })
      }
    }
  }, [serviceJobsTimeTrackers, clockStart])

  const clockStartStop = (startJob) => {
    const includes = ['totalBreakTime', 'userBreakTimes', 'serviceJobUserTimes.serviceJob']

    if (inProgressJob && inProgressJob.serviceJob.id !== serviceJob.id){
      swal.fire({
        title: 'Stop Ongoing Job',
        html: 'Clicking YES will end your current ongoing job.<br>Do you wish to proceed?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#e2001a',
        showClass: 'slide-from-top',
      }).then(({ value }) => {
        if (value){
          jobTimeStopFn(inProgressJob.serviceJob.id, { userRoleId: inProgressJob.serviceJobUserRoleId }).then(({ success, errors }) => {
            if (!success && errors){
              toast.warning(errors[0])
              return
            }
            jobTimeStartFn(serviceJob.id, { userRoleId: serviceJobUser.serviceJobUserRole.id }).then(({ success: successStatus, errors: errorStatus }) => {
              if (!successStatus && errorStatus){
                toast.warning(errors[0])
                return
              }
              setTimerSeconds(userTotalTime)
              setState({ clockStart: !clockStart })
              getUserTimeFn({
                filterStart: '-7d',
                filterEnd: '1d',
                includes: includes.join(','),
              })
            })
          })
        }
      })
    } else {
      (startJob ? jobTimeStopFn(serviceJob.id, { userRoleId: serviceJobUser.serviceJobUserRole.id })
        : jobTimeStartFn(serviceJob.id, { userRoleId: serviceJobUser.serviceJobUserRole.id })).then(({ success, errors }) => {
          if (!success && errors){
            toast.warning(errors[0])
            return
          }
          setState({ clockStart: !clockStart })
          getUserTimeFn({
            filterStart: '-7d',
            filterEnd: '1d',
            includes: includes.join(','),
          })
        })
    }
  }

  useEffect(() => {
    if (serviceJobUserTimes){
      const currentJob = serviceJobUserTimes[Object.keys(serviceJobUserTimes)[Object.keys(serviceJobUserTimes).length - 1]]
      if (currentJob && currentJob.jobEnd === null){
        setState({ inProgressJob: currentJob })
        if (currentJob.serviceJob.id === serviceJob.id){
          setState({ clockStart: true })
        }
      } else setState({ inProgressJob: null, clockStart: false })
    }
  }, [serviceJobUserTimes])

  return (
    <ListItem
      boxProps={{
        borderBottom: '1px solid',
        padding: 'large',
      }}
      flexDirection="column"
      style={{ cursor: 'pointer' }}
      key={serviceJobUser.id}
    >
      <Box flexDirection="row">
        <IconProfile
          name={`${serviceJobUser.user.firstName} ${serviceJobUser.user.lastName}`}
          position={serviceJobUser.serviceJobUserRole?.description}
        />
        <Box flexShrink="0" alignItems="center" justifyContent="flexEnd" width="auto" color="bodyFontLightColor">
          <FontAwesomeIcon icon={faTimes} onClick={() => deleteServiceJobUser(serviceJobUser, deleteFn)} />
        </Box>
      </Box>
      {showDetails && (
        <Box flexDirection="row" alignItems="center" marginTop="medium">
          {loading || creating ? (
            <Box
              flexDirection="column"
              width="fit-content"
            >
              <FontAwesomeIcon icon={faSpinner} color="lightgray" className="fa-spin" size="2x" />
            </Box>
          ) : (
            (inProgressJob && inProgressJob.serviceJob.id === serviceJob.id && serviceJobUser.serviceJobUserRole.id === inProgressJob.serviceJobUserRoleId)
            ? (
              <Box
                flexDirection="column"
                alignItems="center"
                width="30%"
                onClick={() => clockStartStop(true)}
              >
                <CircularProgressbar
                  counterClockwise={false}
                  text={Duration.fromMillis(timerSeconds).toFormat('hh:mm:ss')}
                  value={(timerSeconds / 2.88e+7) * 100}
                  styles={buildStyles({
                  textColor: '#404041',
                  pathColor: '#FFA500',
                })}
                />
              </Box>
            ) : (
              <Box flexDirection="column" alignItems="center" width="fit-content">
                <Box
                  alignItems="center"
                  backgroundColor="green"
                  borderRadius="3rem"
                  height={35}
                  justifyContent="center"
                  onClick={() => clockStartStop(false)}
                  style={{ cursor: 'pointer' }}
                  width={35}
                >
                  <FontAwesomeIcon icon={faPlay} color="white" fill="white" size="md" />
                </Box>
              </Box>
            )
          )}
          <Box flexDirection="column" marginLeft="medium">
            <Text marginBottom="medium" fontSize="xsmall">Total Time Spent: </Text>
            <Text marginBottom="medium" fontSize="small" color="bodyFontLightColor"> {Duration.fromMillis(userTotalTime).toFormat('hh:mm:ss')}</Text>
          </Box>
        </Box>
      )}
    </ListItem>
  )
}

AssignedUser.propTypes = {
  serviceJobUser: PropTypes.object,
  showDetails: PropTypes.bool,
  serviceJob: PropTypes.object,
}

export default AssignedUser
