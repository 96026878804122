/* eslint-disable array-callback-return */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import { Box, StatusBadge, Text, ListItem } from '@campaignhub/suit-theme'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleUp, faAngleDown } from '@fortawesome/pro-light-svg-icons'

import Icons from '@components/Icons'
import styles from '@styles/custom.module.scss'

const defaultState = {
  expanded: false,
  serviceGroupCount: 0,
  serviceState: [],
}

const ServiceGroup = (props) => {
  const { serviceGroup, setJobState, serviceFilter } = props

  const entities = useSelector(reduxState => reduxState.entities)
  const { services } = entities

  const [state, setState] = useSetState(defaultState)
  const { expanded, serviceGroupCount, serviceState } = state

  useEffect(() => {
    let serviceGroupList = serviceGroup.services
      .map(service => ({
        id: service,
        name: services[service]?.name,
        jobCount: services[service]?.jobCount,
        isChecked: serviceFilter.includes(service),
      }))

    if (serviceGroupList.length){
      serviceGroupList = [
        {
          id: 'all',
          description: 'Select All',
          isChecked: serviceGroup.services.every(x => serviceFilter.includes(x)),
          name: 'Select All',
        },
        ...serviceGroupList,
      ]
    }

    setState({ serviceState: serviceGroupList })
  }, [serviceFilter, services])

  const updateFilterState = (checked, serviceId) => {
    if (serviceId === 'all'){
      setState({
        serviceState:
          serviceState.map((data) => {
            const temp = data
            temp.isChecked = checked
            return temp
          }),
      })
      const serviceStateIds = serviceState.filter(x => x.id !== 'all').map(x => x.id)
      if (checked){
        setJobState({ serviceFilter: [...new Set([...serviceFilter, ...serviceStateIds])] })
      } else {
        setJobState({ serviceFilter: serviceFilter.filter(x => !serviceStateIds.includes(x)) })
      }
    } else {
      setState({
        serviceState:
          serviceState.map((data) => {
            const temp = data
            if (serviceId === temp.id){
              temp.isChecked = checked
              if (checked){ setJobState({ serviceFilter: [...serviceFilter, serviceId] }) } else {
                setJobState({ serviceFilter: serviceFilter.filter(i => i !== temp.id) })
              }
            }
            return temp
          }),
      })
    }
  }

  useEffect(() => {
    let count = 0
    serviceState.map((service) => {
      count += service?.jobCount || 0
    })

    setState({ serviceGroupCount: count })
  }, [serviceState])

  return (
    <ListItem
      boxProps={{
        paddingLeft: 'medium',
        marginBottom: 'large',
      }}
      flexDirection="column"
      disableHover
      style={{ cursor: 'pointer' }}
    >
      <Box
        flexDirection="row"
        marginBottom={expanded ? 'medium' : null}
        onClick={() => setState({ expanded: !expanded })}
        lineHeight="1.5"
      >
        <Box
          color="bodyFontLightColor"
          marginLeft="auto"
          width="auto"
          marginRight="medium"
        >
          <Icons name={serviceGroup.name.replace(/\s/g, '').replace('-', '')} />
        </Box>
        <Box flexDirection="column">
          <Text color="bodyFontLightColor" fontAlign="center" fontSize="small">
            {serviceGroup.name}
          </Text>
        </Box>
        <Box flexDirection="column" width="auto">
          <StatusBadge color="gray" text={serviceGroupCount} />
        </Box>
        <Box
          color="bodyFontLightColor"
          marginLeft="medium"
          width="auto"
          marginRight="auto"
        >
          <FontAwesomeIcon icon={expanded ? faAngleUp : faAngleDown} />
        </Box>
      </Box>
      {expanded && (
        <Box flexDirection="column" alignItems="center" lineHeight="1.5" paddingRight="large">
          {serviceState.map(service => (
            <Box key={service.id} flexDirection="row" marginBottom="medium">
              <Box
                color="bodyFontLightColor"
                marginLeft="auto"
                width="auto"
                marginRight="medium"
              >
                <input
                  onChange={e => updateFilterState(e.target.checked, service.id)}
                  type="checkbox"
                  checked={service.isChecked}
                  className={styles.checkbox}
                />
              </Box>
              <Box flexDirection="column">
                <Text color="bodyFontLightColor" fontSize="small" marginRight="medium">
                  {service.name}
                </Text>
              </Box>
              {service.id !== 'all' && (
                <Box flexDirection="column" width="auto">
                  <StatusBadge color="faintGrey" text={service.jobCount ?? 0} />
                </Box>
              )}
            </Box>
          ))}
        </Box>
      )}
    </ListItem>
  )
}

ServiceGroup.propTypes = {
  serviceGroup: PropTypes.object,
  setJobState: PropTypes.func,
  serviceFilter: PropTypes.array,
}

export default ServiceGroup
