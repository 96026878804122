import React, { useContext } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { Box, Button, Image, ListItemWithImage, StatusBadge, Text } from '@campaignhub/suit-theme'

import PageContext from '@contexts/pageContext'
import Icons from '@components/Icons'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/pro-light-svg-icons'
import useClientResource from '@hooks/useClientResource'

const ListView = (props) => {
  const { file } = props

  const { serviceJob } = useContext(PageContext)

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups } = entities

  const { callbacks: { launchClientResourceModal } } = useClientResource()

  return (
    <ListItemWithImage imageComponent={(
      <Box
        alignItems="center"
        as={file?.download_uri ? 'a' : null}
        borderRight={['none', '1px solid']}
        borderBottom={['1px solid', 'none']}
        borderColor={['lineColor', 'lineColor']}
        href={`${file?.download_uri}`}
        justifyContent="center"
        padding="medium"
        style={{ textDecoration: 'none' }}
        width={['100%', 'fit-content']}
        target="_blank"
      >
        <Image
          boxProps={{ backgroundColor: 'hoverLightGrey' }}
          borderRadius={5}
          height={[180, '100%']}
          url={file?.download_uri || ''}
          width={['100%', 200]}
        >
          {file?.content_type === 'application/pdf' && (
            <Box
              alignItems="center"
              color="bodyFontColor"
              display="grid"
              justifyContent="center"
              width="100%"
            >
              <Icons name={serviceGroups[serviceJob.serviceGroupId]?.name} size={40} width="100%" />
            </Box>
          )}
        </Image>
      </Box>
    )}
    >
      <ListItemWithImage.Header>
        <ListItemWithImage.HeaderLeft boxProps={{ maxWidth: '75%!important' }}>
          <ListItemWithImage.HeaderText>
            <Text variant="ellipsis" width="100%">
              {file?.metadata?.name || file.name}
            </Text>
          </ListItemWithImage.HeaderText>
        </ListItemWithImage.HeaderLeft>
      </ListItemWithImage.Header>
      <ListItemWithImage.Footer>
        <ListItemWithImage.FooterLeft boxProps={{ maxWidth: '75%!important' }}>
          <Box fontSize="small" marginBottom="medium" alignItems="center">
            <StatusBadge
              color="mysteryGrey"
              text={file?.content_type}
            />
          </Box>
          <Text color="bodyFontLightColor" fontSize="xsmall" variant="ellipsis" width="100%">
            {file?.metadata?.notes || ''}
          </Text>
        </ListItemWithImage.FooterLeft>
        {file?.metadata?.notes && (
          <ListItemWithImage.FooterRight>
            <Button
              buttonStyle="secondaryEdit"
              icon={<FontAwesomeIcon icon={faEye} />}
              onClick={() => launchClientResourceModal(file)}
              size="medium"
              height={37}
            >
              View
            </Button>
          </ListItemWithImage.FooterRight>
        )}
      </ListItemWithImage.Footer>
    </ListItemWithImage>
  )
}

ListView.propTypes = {
  file: PropTypes.object.isRequired,
}

export default ListView
