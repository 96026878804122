const customMetaData = {
  dimensions: 'Dimensions',
  size: 'File Size',
  exposuretime: 'Exposure Time',
  fnumber: 'F Number',
  iso: 'ISO',
  colorspace: 'Colour Space',
  make: 'Camera Maker',
  model: 'Camera Model',
  datetimeoriginal: 'Date Taken',

}

export default customMetaData
