/* eslint-disable max-len */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import swal from 'sweetalert2'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import {
  Box, Button, LoadingModule, MainContent, ModalContext, TabBar, Text,
} from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useReduxAction from '@hooks/useReduxAction'
import useServiceJobUser from '@hooks/useServiceJobUser'
import useServiceJob from '@hooks/useServiceJob'
import useServiceJobUserRoles from '@hooks/useServiceJobUserRoles'

import ServiceJobList from '@components/ServiceJobList'
import Statistics from '@components/Statistics'

import PageContext from '@contexts/pageContext'
import CreateServiceJobViewModal from '@modals/CreateServiceJobViewModal'
import EditServiceJobViewModal from '@modals/EditServiceJobViewModal'

import customFilters from '@functions/customFilters'
import { getEntityByName } from '@functions/getEntityByName'

import custom from '@styles/custom.module.scss'

import { DateTime } from 'luxon'
import csv from '@functions/csv'
import ServiceJobsFilter from './components/ServiceJobsFilter'

const createFilter = (filterParams, createFn, setState) => {
  createFn(filterParams).then(({ success, errors }) => {
    if (!success){
      toast.warning(errors[0])
      return
    }

    setState({ showCreateServiceJobViewModal: false })
  })
}

const updateFilter = (filter, updateFn, setState) => {
  updateFn(filter).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showEditServiceJobViewModal: false })
  })
}

const deleteFilter = (filter, deleteFn, setState) => {
  deleteFn(filter).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showEditServiceJobViewModal: false })
    toast.success('View Deleted')
  })
}

const assignServiceJobUser = (serviceJobUserParams, jobAssigned, createFn, navigate) => {
  if (jobAssigned){
    toast.success('Assigned Job')
    navigate(`/service-jobs/${jobAssigned}`)
    return
  }

  if (serviceJobUserParams){
    createFn(serviceJobUserParams).then(({ success, errors, redirectUrl }) => {
      if (!success){
        toast.warning(errors[0])
        return
      }

      if (success){
        toast.success('Job Taken')
        window.location.href = redirectUrl
      }
    })
  }
}

const confirmArchive = (archive, archiveFn) => {
  swal
    .fire({
      title: `Bulk ${archive ? 'archive' : 'unarchive'}`,
      text: `This will ${archive ? 'archive' : 'unarchive'} all jobs for the selected filter/s. Do you wish to proceed?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, proceed.',
      confirmButtonColor: '#DD6B55',
    })
    .then(({ value }) => {
      if (value){
        archiveFn(archive)
      }
    })
}

const callbacks = (component, props, setState) => {
  const componentCallbacks = {
    CreateServiceJobViewModal: {
      closeModal: () => setState({ showCreateServiceJobViewModal: false }),
      createFilter: (filterParams, createFn) => createFilter(filterParams, createFn, setState),
    },
    EditServiceJobViewModal: {
      closeModal: () => setState({ showEditServiceJobViewModal: false }),
      deleteFilter: (filter, deleteFn) => deleteFilter(filter, deleteFn, setState),
      updateFilter: (filter, updateFn) => updateFilter(filter, updateFn, setState),
    },
  }

  return componentCallbacks[component] || {}
}

const defaultState = {
  accountManagerFilter: [],
  activeTabBarItemKey: {
    key: 'jobs',
    filterStatuses: [],
    takeStatuses: [],
    takeRole: [],
  },
  assigned: false,
  classificationFilter: [],
  clientFilter: [],
  customFilter: [
    customFilters.studioNotDone.id,
    customFilters.notArchived.id,
  ],
  filterJob: 0,
  filterLimit: 50,
  filterTags: [],
  hideArchivedJobs: true,
  hideDoneJobs: true,
  jobAssigned: null,
  serviceDateFilter: {
    filterEnd: '',
    filterStart: '',
  },
  serviceFilter: [],
  setTotal: 0,
  showCreateServiceJobViewModal: false,
  showEditServiceJobViewModal: false,
  statusFilter: [],
  userFilter: [],
  serviceUserFilter: [],
  userToAssign: null,
  runReport: false,
  archiving: false,
}

const ServiceJobs = (props) => {
  const [state, setState] = useSetState(defaultState)
  const {
    activeTabBarItemKey, clientFilter, customFilter, filterJob, filterLimit, filterTags, hideArchivedJobs, hideDoneJobs,
    jobAssigned, serviceFilter, showCreateServiceJobViewModal, showEditServiceJobViewModal, statusFilter, userFilter, serviceUserFilter,
    userToAssign, classificationFilter, accountManagerFilter, serviceDateFilter, runReport, archiving,
  } = state

  const navigate = useNavigate()

  const { currentUser } = useCurrentUser()

  const modalContext = useModals()
  const { callbacks: { setModalData } } = modalContext

  const pageContext = {
    callbacks: {
      showCreateServiceJobViewModal: (payload) => {
        setModalData('CreateServiceJobViewModal', payload)
        setState({ showCreateServiceJobViewModal: true })
      },
      showEditServiceJobViewModal: (payload) => {
        setModalData('EditServiceJobViewModal', payload)
        setState({ showEditServiceJobViewModal: true })
      },
    },
  }

  useEffect(() => {
    const filters = []
    if (hideDoneJobs) filters.push(customFilters.studioNotDone.id)
    if (hideArchivedJobs) filters.push(customFilters.notArchived.id)

    setState({ customFilter: filters })
  }, [hideDoneJobs, hideArchivedJobs])

  const includes = ['campaign', 'client', 'details', 'service', 'status', 'users', 'notes']
  const options = {
    ...accountManagerFilter?.length && { accountManagers: accountManagerFilter.join(',') },
    ...classificationFilter?.length && { classifications: classificationFilter.join(',') },
    ...clientFilter?.length && { clients: clientFilter.join(',') },
    ...customFilter?.length && { customFilters: customFilter.join(',') },
    ...filterTags?.length && { tags: filterTags.join(',') },
    ...serviceDateFilter?.filterEnd && { serviceDateFilterEnd: serviceDateFilter.filterEnd },
    ...serviceDateFilter?.filterStart && { serviceDateFilterStart: serviceDateFilter.filterStart },
    ...serviceFilter?.length && { services: serviceFilter.join(',') },
    ...statusFilter?.length && { serviceJobStatuses: statusFilter.join(',') },
    ...userFilter?.length && { users: userFilter.join(',') },
    ...serviceUserFilter?.length && { serviceUsers: serviceUserFilter.join(',') },
    includes: includes.join(','),
    pageSize: filterLimit,
    pageNumber: 1,
    orderBy: 'clientDeadline',
  }

  useReduxAction('serviceJobs', 'loadServiceJobs', options, [filterJob])

  const {
    loading, additionalInfo, results: filteredServiceJobs,
  } = useSelector(reduxState => reduxState.serviceJobs)

  function setActiveTabBarItemKey(key, filterStatuses, takeStatuses, takeRole){
    setState({
      activeTabBarItemKey: {
        key,
        filterStatuses,
        takeStatuses,
        takeRole,
      },
      statusFilter: filterStatuses,
      filterJob: filterJob + 1,
    })
  }

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses, users } = entities

  const qcTakeStatuses = [
    getEntityByName(serviceJobStatuses, 'AwaitingQC')?.id,
  ]
  const qcFilterStatuses = [...qcTakeStatuses, getEntityByName(serviceJobStatuses, 'InQC1')?.id]

  const rejectionTakeStatuses = [
    getEntityByName(serviceJobStatuses, 'ChangeRequest')?.id,
  ]
  const rejectionFilterStatuses = [getEntityByName(serviceJobStatuses, 'ChangeRequest')?.id]

  const serviceJobUserPayload = useServiceJobUser()
  const {
    callbacks: { assignServiceJobUser: createFn },
  } = serviceJobUserPayload

  const takeAServiceJob = (activeTab) => {
    setState({ jobAssigned: null })

    if (activeTab.key === 'jobs' || Object.values(filteredServiceJobs).length === 0) return

    const serviceJobUserRoleId = activeTab.key === 'qc' ? 2 : 3
    let takeJob = Object.values(filteredServiceJobs).find(serviceJob => activeTab.takeStatuses.includes(serviceJob.status.id)
      && serviceJob.users.find(serviceJobUser => serviceJobUser.serviceJobUserRoleId === serviceJobUserRoleId && serviceJobUser.user.id === currentUser.id))

    if (!takeJob){
      takeJob = Object.values(filteredServiceJobs).find(serviceJob => activeTab.takeStatuses.includes(serviceJob.status.id))

      if (takeJob){
        setState({
          userToAssign: {
            serviceJobId: takeJob.id,
            userId: currentUser.id,
            serviceJobUserRoleId,
          },
        })
      }
      return
    }

    if (takeJob) setState({ jobAssigned: takeJob.id })
  }
  const { serviceJobUserRoles } = useServiceJobUserRoles()
  const createReportJobs = reportServiceJobs => Array.from(Object.values(reportServiceJobs)).map((job) => {
    const editors = job.users.filter(user => serviceJobUserRoles[user.serviceJobUserRoleId].name === 'EditorDrafterCopywriter')
    const qcs = job.users.filter(user => serviceJobUserRoles[user.serviceJobUserRoleId].name === 'EditorDrafterCopywriter')
    const rejections = job.users.filter(user => serviceJobUserRoles[user.serviceJobUserRoleId].name === 'RevisionAssignee')
    const service = DateTime.fromISO(job.dateService, { zone: currentUser.timeZone })
    const deadline = DateTime.fromISO(job.clientDeadline, { zone: currentUser.timeZone })
    let agents = job.owner ?? ''
    if (job.collaborators != null){
      agents = `${agents },${ job.collaborators.join(',')}`
    }

    const reportJob = {
      'Job/Campaign name': job.campaign.name,
      Agents: agents,
      Office: job.client.name,
      Classification: job.clientClassification,
      Details: job.orderDetails,
      Service: job.service.description,
      'CMS Link': job.url,
      Status: job.status.description,
      Provider: !job.serviceUsername ? 'Available' : job.serviceUsername,
      Editor: !editors.length ? 'Available' : editors.map(serviceJobUser => `${serviceJobUser.user.firstName} ${serviceJobUser.user.lastName}`).join(', '),
      QC: !qcs.length ? 'Available' : qcs.map(serviceJobUser => `${serviceJobUser.user.firstName} ${serviceJobUser.user.lastName}`).join(', '),
      Rejection: !rejections.length ? 'Available' : rejections.map(serviceJobUser => `${serviceJobUser.user.firstName} ${serviceJobUser.user.lastName}`).join(', '),
      'Service Date': service.invalid ? '' : service.toFormat('y-LL-dd'),
      'Client Deadline': deadline.invalid ? '' : deadline.toFormat('y-LL-dd HH:mm:ss'),
    }
    return reportJob
  })

  function getReportCallback(response){
    if (response.success){
      const reporData = createReportJobs(response.data)
      csv(reporData, 'jobs')
    }
    setState({ runReport: false })
  }

  const { callbacks: { fetchServiceJobs } } = useServiceJob()
  function generateCSVReport(){
    setState({ runReport: true })
    const reportIncludes = ['campaign', 'client', 'details', 'service', 'status', 'users'].join(',')
    const isReport = true
    const reportOptions = { ...options, reportIncludes, isReport }
    delete reportOptions.pageSize
    fetchServiceJobs(reportOptions, getReportCallback)
  }

  function archiveCallback(response){
    if (!response.success){
      toast.error('Something went wrong.')
    }
    setState({ archiving: false, filterJob: filterJob + 1 })
  }

  function archiveFn(bulkArchive){
    setState({ archiving: true })
    const archiveOptions = { ...options, bulkArchive }
    fetchServiceJobs(archiveOptions, archiveCallback)
  }

  useEffect(() => {
    takeAServiceJob(activeTabBarItemKey)
  }, [filteredServiceJobs])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <MainContent
          offset={{ left: 0, top: 0 }}
          width="100%"
          style={{ display: 'flex', flexDirection: 'column' }}
        >
          <Box flexDirection="column" overflowY="auto" padding="large">
            <ServiceJobsFilter
              setJobState={setState}
              jobState={state}
              deadlineFilterTotal={additionalInfo.customFilters?.filter(x => x.type === 'deadline')}
              agencyFilterItems={additionalInfo.customFilters?.filter(x => x.type === 'platform')}
              customFilterItems={additionalInfo.customFilters?.filter(x => x.type === 'custom')}
              showAccountManagerFilter
              showClassificationFilter
              showServiceDateFilter
              showServiceUserFilter
              showStudioUserFilter
            />
            <Statistics hidden />
            <TabBar
              items={[
                {
                  key: 'jobs',
                  title: 'Jobs',
                  onClick: () => setActiveTabBarItemKey('jobs', [], []),
                },
                {
                  key: 'qc',
                  title: 'QC',
                  onClick: () => setActiveTabBarItemKey('qc', qcFilterStatuses, qcTakeStatuses, 2),
                },
                {
                  key: 'rejection',
                  title: 'Rejection',
                  onClick: () => setActiveTabBarItemKey('rejection', rejectionFilterStatuses, rejectionTakeStatuses, 3),
                },
              ]}
              selectedItemKey={activeTabBarItemKey.key}
              invertColors
            />
            {loading ? <LoadingModule loading={loading} /> : (
              <>
                {Object.values(filteredServiceJobs).length > 0 && (
                  <Box
                    flexDirection="row"
                    paddingBottom="medium"
                    alignItems="center"
                    backgroundColor="white"
                    padding="large"
                    borderLeft="1px solid"
                    borderRight="1px solid"
                    borderColor="lineColor"
                  >
                    <Box
                      flexDirection="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Text fontSize="small">
                        {Object.values(filteredServiceJobs).length} of {additionalInfo.totalCount} {Object.values(filteredServiceJobs).length > 1 ? ' Jobs' : ' Job'}
                      </Text>
                      {['brigettepearl2@gmail.com', 'pearls@contenthouse.io', 'monica.ramos@contenthouse.io', 'montest.prodman@gmail.com', 'geviray@gmail.com'].includes(currentUser.email) && (
                        <Button
                          buttonStyle="primaryCreate"
                          size="small"
                          width="auto"
                          marginLeft="auto"
                          onClick={() => confirmArchive(hideArchivedJobs, archiveFn)}
                          loading={archiving}
                        >
                          Bulk {hideArchivedJobs ? 'Archive' : 'Unarchive' }
                        </Button>
                      )}
                      {['ProductionManager'].includes(currentUser.userRole.name) && (
                        <Button
                          buttonStyle="primaryCreate"
                          size="small"
                          width="auto"
                          marginLeft="medium"
                          onClick={() => generateCSVReport()}
                          loading={runReport}
                        >
                          Download
                        </Button>
                      )}
                    </Box>
                    <Box alignItems="center" marginLeft="auto" justifyContent="flexEnd" width="auto" flexShrink="0" paddingLeft="small">
                      {activeTabBarItemKey.key !== 'jobs' && (jobAssigned || userToAssign !== null)
                        && (
                          <Button
                            buttonStyle="ghostCreate"
                            size="small"
                            onClick={() => assignServiceJobUser(userToAssign, jobAssigned, createFn, navigate)}
                            width="auto"
                          >
                            Take a {activeTabBarItemKey.key === 'qc' ? 'QC' : 'Rejection'} Job
                          </Button>
                        )}
                    </Box>
                  </Box>
                )}
                <Box flexDirection="column" variant="white" className={custom.scroll}>
                  <ServiceJobList
                    filterJob={filterJob}
                    serviceJobs={Object.values(filteredServiceJobs)}
                    totalJobCount={additionalInfo.totalCount}
                    showUsers={!['BackOffice'].includes(users[currentUser.id]?.userRole.name)}
                    showQC={activeTabBarItemKey.key !== 'rejection'}
                    showRejection={activeTabBarItemKey.key !== 'qc'}
                    setJobState={setState}
                  />
                </Box>
              </>
            )}
          </Box>
        </MainContent>
        <CreateServiceJobViewModal
          callbacks={callbacks('CreateServiceJobViewModal', props, setState)}
          showModal={showCreateServiceJobViewModal}
          accountManagerFilter={accountManagerFilter}
          classificationFilter={classificationFilter}
          clientFilter={clientFilter}
          customFilter={customFilter}
          filterTags={filterTags}
          serviceDateFilter={serviceDateFilter}
          serviceFilter={serviceFilter}
          serviceUserFilter={serviceUserFilter}
          statusFilter={statusFilter}
          userFilter={userFilter}
        />

        <EditServiceJobViewModal
          callbacks={callbacks('EditServiceJobViewModal', props, setState)}
          showModal={showEditServiceJobViewModal}
          accountManagerFilter={accountManagerFilter}
          classificationFilter={classificationFilter}
          clientFilter={clientFilter}
          customFilter={customFilter}
          filterTags={filterTags}
          serviceDateFilter={serviceDateFilter}
          serviceFilter={serviceFilter}
          serviceUserFilter={serviceUserFilter}
          statusFilter={statusFilter}
          userFilter={userFilter}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

export default ServiceJobs
