import { isValidEmail } from '@functions/validations'
import managementState from '@models/User/management'
import editorState from '@models/User/editor'
import providerState from '@models/User/provider'
import qcState from '@models/User/qc'
import productionManagerState from '@models/User/production_manager'
import specialistState from '@models/User/specialist'
import agentState from '@models/User/agent'

const state = {
  id: null,
  editor: editorState,
  email: '',
  employment: '',
  flags: [],
  jobtrack_username: '',
  management: managementState,
  name: '',
  production_manager: productionManagerState,
  provider: providerState,
  qc: qcState,
  roles: [],
  specialist: specialistState,
  username: '',
  agent: agentState
}

export const requiredFields = [
  { key: 'email', validation: isValidEmail, invalidMessage: 'Invalid Email format' },
  { key: 'username', validation: isValidEmail, invalidMessage: 'Invalid Email format' },
  { key: 'name' },
  { key: 'employment' },
  { key: 'roles' },
]

export default state
