import { useContext } from 'react'
import { useSelector } from 'react-redux'
import cloneDeep from 'lodash/cloneDeep'

import { useForm, useLatestEntity, useThunkDispatch } from '@campaignhub/react-hooks'

import defaultState, { requiredFields } from '@models/User/user'

import PageContext from '@contexts/pageContext'

import * as userActions from '@redux/modules/userV3'

const filterUserParams = (userParams) => {
  let filteredParam = userParams

  if (!(userParams.roles.includes('Provider') && userParams.roles.includes('Editor') && userParams.roles.includes('QC'))){
    const { ...userParam } = filteredParam
    filteredParam = userParam
  }

  if (filteredParam.provider){
    const slots = Object.entries(filteredParam.provider.slots || {})
    let filteredSlots = slots.filter(x => x[1] !== undefined)
    if (filteredParam.id === null){
      filteredSlots = slots.filter(x => x[1].length > 0)
    }

    const updatedSlots = Object.fromEntries(filteredSlots)
    filteredParam.provider.slots = updatedSlots
    delete filteredParam.provider.slots_selected

    if (['', null].includes(filteredParam.provider?.primary_category)){
      delete filteredParam.provider.primary_category
      delete filteredParam.provider?.secondary_categories
    }
  }

  filteredParam.provider = userParams.roles.includes('Provider') ? filteredParam.provider : null
  filteredParam.editor = userParams.roles.includes('Editor') ? filteredParam.editor : null
  filteredParam.qc = userParams.roles.includes('QC') ? filteredParam.qc : null
  filteredParam.production_manager = userParams.roles.includes('Production_Manager') ? filteredParam.production_manager : null
  filteredParam.specialist = userParams.roles.includes('Specialist') ? filteredParam.specialist : null
  filteredParam.agent = userParams.roles.includes('Agent') ? filteredParam.agent : null

  if (filteredParam.id === null){
    const { id, ...newUserParam } = filteredParam
    filteredParam = newUserParam
  }

  return filteredParam
}

const createUser = (userParams, dispatch, requestOptions) => {
  const { createUserV3: createFn } = userActions
  const { ...userParam } = userParams

  const rating = parseInt(userParams.management.rating, 10)
  if (Number.isNaN(rating)){
    delete userParam.management.rating
  } else {
    userParam.management.rating = rating
  }

  const filtered = filterUserParams(userParam)
  if (Object.entries(filtered.provider?.slots || {}).length === 0){
    delete filtered.provider?.slots
  }

  if (['', null].includes(filtered.provider?.primary_category)){
    delete filtered.provider.primary_category
    delete filtered.provider?.secondary_categories
  }

  return dispatch(createFn(filtered, requestOptions))
}

const updateUser = (userParams, dispatch, requestOptions) => {
  const { updateUserV3: updateFn } = userActions

  return dispatch(updateFn(filterUserParams(userParams), requestOptions))
}

const deleteUser = (user, dispatch) => {
  const { deleteUserV3: deleteFn } = userActions
  return dispatch(deleteFn(user))
}

const editUser = (user, dispatch, showEditUserModal) => new Promise((resolve, reject) => {
  if (showEditUserModal){
    const payload = {
      callbacks: {
        deleteUser: () => deleteUser(user, dispatch),
        updateUser: userParams => updateUser(userParams, dispatch),
      },
      user,
    }

    showEditUserModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditUserModal not defined in PageContext callbacks'))
})

export function useUserForm(user = {}){
  const userForm = useForm(defaultState, { entity: { ...defaultState, ...user }, requiredFields, validateOn: 'change' }, [user.id, user.cache_key])
  if (userForm.entityState.roles.includes('Provider')){
    userForm.entityState.provider.services_selected = user?.provider?.services
  }
  if (userForm.entityState.roles.includes('Editor')){
    userForm.entityState.editor.services_selected = user?.editor?.services
  }
  if (userForm.entityState.roles.includes('QC')){
    userForm.entityState.qc.services_selected = user?.qc?.services
  }
  if (userForm.entityState.roles.includes('Production_Manager')){
    userForm.entityState.production_manager.services_selected = user?.production_manager?.services
  }
  if (userForm.entityState.roles.includes('Specialist')){
    userForm.entityState.specialist.services_selected = user?.specialist?.services
  }
  const initialValue = Object.keys(user).length === 0 ? defaultState : cloneDeep(userForm.entityState)
  return {
    ...userForm,
    initialValue,
  }
}

const useUserV3 = (initUser = {}) => {
  const { entity: userV3 } = useLatestEntity(initUser, 'usersV3')

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showEditUserModal } = callbacks || {}

  const { creating, deleting, loading, updating } = useSelector(reduxState => reduxState.usersV3)

  return {
    userV3,
    callbacks: {
      editUser: () => editUser(userV3, dispatch, showEditUserModal),
      updateUser: (userParams, requestOptions) => updateUser(userParams, dispatch, requestOptions),
      createUser: (userParams, requestOptions) => createUser(userParams, dispatch, requestOptions),
      deleteUser: userParams => deleteUser(userParams, dispatch),
    },
    creating,
    deleting,
    loading,
    updating,
  }
}

export default useUserV3
