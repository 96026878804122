import { isValidEmail } from '@functions/validations'

const state = {
  id: null,
  flags: [],
  name: '',
  reference: '',
  dusk_times: [],
  default_travel_time: 0,
  support_email: ''
}

export const requiredFields = [
  { key: 'name' },
  { key: 'support_email', validation: isValidEmail, invalidMessage: 'Invalid Email format' },
]

export default state
