import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import PageContext from '@contexts/pageContext'
import { toast } from 'react-toastify'

import { Box, ModalContext } from '@campaignhub/suit-theme'

import { useModals, useSetState } from '@campaignhub/react-hooks'

import CreateOrEditBlockoutModal from '@modals/CreateOrEditBlockoutModal'
import EditFlagsModal from '@modals/EditFlagsModal'

import validateEntityStateCustomFields from '@functions/validateEntityStateCustomFields'
import { customProviderRequiredFields } from '@models/User/provider'
import { customEditorRequiredFields } from '@models/User/editor'
import { customQcRequiredFields } from '@models/User/qc'
import { customProductionManagerRequiredFields } from '@models/User/production_manager'
import { customSpecialistRequiredFields } from '@models/User/specialist'
import GeneralDetail from './components/GeneralDetail'
import ManagementDetail from './components/ManagementDetail'
import ProviderDetail from './components/ProviderDetail'
import EditorDetail from './components/EditorDetail'
import QcDetail from './components/QcDetail'
import ProductionManagerDetail from './components/ProductionManagerDetail'
import Specialist from './components/SpecialistDetail'
import AgentDetail from './components/AgentDetail'

const defaultState = {
  customErrors: {},
  showBlockoutModal: false,
  showEditFlagsModal: false,
  selectedRole: '',
}

const createBlockout = (blockoutParams, createFn, setState) => {
  createFn(blockoutParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showBlockoutModal: false })
  })
}

const deleteBlockout = (deleteFn, setState) => {
  deleteFn().then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showBlockoutModal: false })
  })
}

const updateBlockout = (blockoutParams, updateFn, setState) => {
  updateFn(blockoutParams).then(({ success, errors }) => {
    if (!success && errors){
      toast.warning(errors[0])
      return
    }

    setState({ showBlockoutModal: false })
  })
}

const callbacks = (component, setState) => {
  const componentCallbacks = {
    GeneralDetail: {
      setSelectedRole: value => setState({ selectedRole: value }),
    },
    ProviderDetail: {

    },
    CreateOrEditBlockoutModal: {
      closeModal: () => setState({ showBlockoutModal: false }),
      createBlockout: (blockoutParams, createFn) => createBlockout(blockoutParams, createFn, setState),
      deleteBlockout: deleteFn => deleteBlockout(deleteFn, setState),
      updateBlockout: (blockoutParams, updateFn) => updateBlockout(blockoutParams, updateFn, setState),
    },
    EditFlagsModal: {
      closeModal: () => setState({ showEditFlagsModal: false }),
    },
  }

  return componentCallbacks[component] || {}
}

const UserDetail = (props) => {
  const { userForm, customErrors, callback } = props

  const { entityState } = userForm
  const { setCustomErrors } = callback

  const [state, setState] = useSetState(defaultState)
  const { selectedRole, showBlockoutModal, showEditFlagsModal } = state

  const modalContext = useModals()
  const {
    callbacks: { setModalData },
  } = modalContext

  const pageContext = {
    callbacks: {
      showBlockoutModal: (payload) => {
        setModalData('CreateOrEditBlockoutModal', payload)
        setState({ showBlockoutModal: true })
      },
      showEditFlagsModal: (payload) => {
        setModalData('EditFlagsModal', payload)
        setState({ showEditFlagsModal: true })
      },
    },
  }

  useEffect(() => {
    const customRequiredFields = entityState.roles.includes('Provider') ? customProviderRequiredFields
      : entityState.roles.includes('Editor') ? customEditorRequiredFields
      : entityState.roles.includes('QC') ? customQcRequiredFields
      : entityState.roles.includes('Production_Manager') ? customProductionManagerRequiredFields
      : entityState.roles.includes('Specialist') ? customSpecialistRequiredFields : []

    setCustomErrors(validateEntityStateCustomFields(entityState, customRequiredFields))
  }, [entityState])

  return (
    <PageContext.Provider value={pageContext}>
      <ModalContext.Provider value={modalContext}>
        <Box flexDirection="column">
          <GeneralDetail
            callbacks={{ ...callbacks('GeneralDetail', setState) }}
            userForm={userForm}
          />
          {selectedRole !== 'Agent' && (
          <ManagementDetail
            callbacks={{ setCustomErrors }}
            userForm={userForm}
            customErrors={customErrors}
          />
          )}
          {selectedRole === 'Provider' && (
          <ProviderDetail
            callbacks={{ setCustomErrors }}
            userForm={userForm}
            customErrors={customErrors}
          />
          )}
          {selectedRole === 'Agent' && (
          <AgentDetail
            callbacks={{ setCustomErrors }}
            userForm={userForm}
            customErrors={customErrors}
          />
          )}
          {selectedRole === 'Editor' && (
          <EditorDetail
            callbacks={{ setCustomErrors }}
            userForm={userForm}
            customErrors={customErrors}
          />
          )}
          {selectedRole === 'QC' && (
          <QcDetail
            callbacks={{ setCustomErrors }}
            userForm={userForm}
            customErrors={customErrors}
          />
          )}
          {selectedRole === 'Production_Manager' && (
          <ProductionManagerDetail
            callbacks={{ setCustomErrors }}
            userForm={userForm}
            customErrors={customErrors}
          />
          )}
          {selectedRole === 'Specialist' && (
            <Specialist
              callbacks={{ setCustomErrors }}
              userForm={userForm}
              customErrors={customErrors}
            />
          )}

        </Box>
        <CreateOrEditBlockoutModal
          callbacks={callbacks('CreateOrEditBlockoutModal', setState)}
          showModal={showBlockoutModal}
          userId={entityState.id}
        />
        <EditFlagsModal
          callbacks={callbacks('EditFlagsModal', setState)}
          showModal={showEditFlagsModal}
          form={userForm}
        />
      </ModalContext.Provider>
    </PageContext.Provider>
  )
}

UserDetail.propTypes = {
  userForm: PropTypes.object.isRequired,
  customErrors: PropTypes.array,
  callback: PropTypes.object,
}

export default UserDetail
