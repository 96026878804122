import React, { useContext, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'

import {
  Box, DashboardModule, StatusBadge, Text,
} from '@campaignhub/suit-theme'

import { useSetState } from '@campaignhub/react-hooks'
import PageContext from '@contexts/pageContext'
import useCurrentUser from '@hooks/useCurrentUser'

import { getServiceStatusColor } from '@functions/getServiceStatusColor'
import TasksImageUrl from '@components/assets/tasks.svg'
import userTypes from '@functions/userTypes'

const defaultState = {
  agent: '',
  dusktotalImages: 0,
  otherAttendees: [],
  rsDetails: '',
  serviceDescription: [],
  workbaseDetails: '',
}

const JobSummary = (props) => {
  const { details, hidden, serviceJobLoading, showJobLength } = props

  const [state, setState] = useSetState(defaultState)
  const { agent, otherAttendees, rsDetails, serviceDescription, workbaseDetails } = state

  const { serviceJob, showDuskPositional, serviceJobs } = useContext(PageContext)
  const { campaign, client, ctReferenceId, totalAssets } = serviceJob

  const { currentUser: { timeZone }, currentUser } = useCurrentUser()

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceJobStatuses } = entities

  const duskServiceJob = Object.values(serviceJobs).filter(x => ['Dusk Photography'].includes(x.service?.name) && x.campaign?.id === serviceJob.campaign.id)[0]

  useEffect(() => {
    if (details){
      setState({
        agent: details.find(x => x.entityFieldType.name === 'Agent')?.value || '',
        serviceDescription: details.filter(x => x.entityFieldType.name === 'Description'),
        otherAttendees: details.filter(x => x.entityFieldType.name === 'OtherAttendee'),
        rsDetails: details.find(x => x.entityFieldType.name === 'RealStudiosDetails')?.value || '',
        workbaseDetails: details.find(x => x.entityFieldType.name === 'WorkbaseDetails')?.value || '',
      })
    }
  }, [details])

  if (hidden) return null

  return (
    <DashboardModule
      title="Job Summary"
      headerRight={(
        !serviceJobLoading && (
          <Box display="block" width="auto" textAlign="end">
            <StatusBadge color="#282B42" ghost text={serviceJob.service?.name} />
            <StatusBadge
              boxProps={{ marginLeft: 'small' }}
              color={getServiceStatusColor(serviceJobStatuses[serviceJob.serviceJobStatusId]?.name)}
              ghost
              text={serviceJobStatuses[serviceJob.serviceJobStatusId]?.description}
            />
            <StatusBadge
              boxProps={{ marginLeft: 'small' }}
              ghost
              color={workbaseDetails ? '#22b8cf' : rsDetails ? 'green' : 'orange'}
              text={workbaseDetails ? 'CH' : rsDetails ? 'RH' : 'CT'}
            />
          </Box>
        )
      )}
    >
      <Box
        backgroundColor="white"
        borderRadius={5}
        flexDirection={['column-reverse', 'row']}
        padding="large"
      >
        <Box
          flexDirection="column"
          marginRight={[0, 'large']}
          width={['100%', '70%', '75%']}
        >
          <Box flexDirection={['column', 'row', 'row']} gridGap="large">
            <Box flexDirection="column">
              <Box flexDirection="column" marginBottom="large">
                <Text marginBottom="medium" fontSize="small">Appointment Details</Text>
                <Text color="bodyFontLightColor" marginBottom="small" fontSize="small">
                  {campaign?.name}
                </Text>
                {serviceJob.service?.onSite && (
                  <Text color="bodyFontLightColor" marginBottom="small" fontSize="small">
                    {DateTime.fromISO(serviceJob.dateService, { zone: timeZone }).toFormat('LLLL dd, y, hh:mm a')}
                    {showJobLength ? ` (${!serviceJob.serviceJobLength ? 0 : serviceJob.serviceJobLength} minutes)` : '' }
                  </Text>
                )}
              </Box>
              <Box flexDirection="column">
                <Text marginBottom="medium" fontSize="small">Agency Details</Text>
                <Text color="bodyFontLightColor" marginBottom="small" fontSize="small">
                  {client?.name}
                </Text>
                {agent && (
                  <Text color="bodyFontLightColor" marginBottom="small" fontSize="small">
                    {JSON.parse(agent).name} {JSON.parse(agent).contactNumber}
                  </Text>
                )}
              </Box>
              {rsDetails && (
                <Box flexDirection="column" marginTop="large">
                  <Text marginBottom="medium" fontSize="small">RH Contact</Text>
                  <Text color="bodyFontLightColor" marginBottom="small" fontSize="small">
                    {JSON.parse(rsDetails).value.slice(JSON.parse(rsDetails).value.indexOf('Agent'), JSON.parse(rsDetails).value.indexOf('Order') - 2)}
                  </Text>
                </Box>
              )}
            </Box>
            <Box flexDirection="column">
              <Text marginBottom="medium" fontSize="small">Order Details</Text>
              <Text color="bodyFontLightColor" marginBottom="small" fontSize="small">
                {serviceJob.service?.name}
              </Text>
              {serviceDescription.map(desc => (
                <Text key={desc.id} color="bodyFontLightColor" marginBottom="small" fontSize="small">
                  {JSON.parse(desc.value).value}
                </Text>
              ))}
              {currentUser?.userRole.userTypeId !== userTypes.find(x => x.name === 'OnSite').id && ctReferenceId && (
                <Text color="bodyFontLightColor" marginBottom="small" fontSize="small">
                  Total Images: {totalAssets || '0'}
                </Text>
              )}
              {showDuskPositional && (
                <Text color="bodyFontLightColor" marginBottom="small" fontSize="small">
                  Dusk Positionals: {duskServiceJob?.totalAssets}
                </Text>
              )}
              {(ctReferenceId) && (
                <Text color="bodyFontLightColor" marginBottom="small" fontSize="small">
                  {`Jobtrack Job No: ${ctReferenceId}`}
                </Text>
              )}
              {otherAttendees.length > 0 && (
                <Box flexDirection="row" marginBottom="small">
                  <Text style={{ flexShrink: 0 }} color="bodyFontLightColor" fontSize="small" marginRight="small">
                    Other Attendees:
                  </Text>
                  <Box flexDirection="column">
                    {otherAttendees.map(attendee => (
                      <Text key={attendee.value} color="bodyFontLightColor" marginBottom="small" fontSize="small">
                        {JSON.parse(attendee.value).name} {JSON.parse(attendee.value).contactNumber}
                      </Text>
                    ))}
                  </Box>
                </Box>
              )}
              {showDuskPositional && (
                <Text color="bodyFontLightColor" marginBottom="small" fontSize="small">
                  Dusk: {duskServiceJob?.serviceUsername}
                </Text>
              )}
            </Box>
          </Box>
        </Box>
        <Box
          alignItems="center"
          justifyContent="center"
          marginLeft={[0, 'auto']}
          marginBottom={['large', 0]}
          width={['100%', '30%', '25%']}
        >
          <img alt="Blank State" src={TasksImageUrl} style={{ maxWidth: '100%' }} />
        </Box>

      </Box>

    </DashboardModule>
  )
}

JobSummary.propTypes = {
  details: PropTypes.array,
  hidden: PropTypes.bool,
  serviceJobLoading: PropTypes.bool,
  showJobLength: PropTypes.bool,
}

export default JobSummary
