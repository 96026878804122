import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'
import swal from 'sweetalert2'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay, faStop } from '@fortawesome/pro-solid-svg-icons'

import { useOutsideClick, useSetState } from '@campaignhub/react-hooks'
import { Box, Button, Link, LoadingBubbles, Text } from '@campaignhub/suit-theme'

import useCurrentUser from '@hooks/useCurrentUser'
import useCurrentUserV3 from '@hooks/useCurrentUserV3'
import useReduxAction from '@hooks/useReduxAction'
import useTimeTracker from '@hooks/useTimeTracker'
import useServiceJobTimeTracker from '@hooks/useServiceJobTimeTracker'
import secondsToTimeFormat from '@functions/secondsToTimeFormat'

import Logs from './components/Logs'
import styles from './styles.module.scss'

const defaultState = {
  clockStart: null,
  isUserClockedIn: false,
  isUserInBreak: false,
  showLogs: false,
  showTimeTracker: false,
  userCurrentJob: null,
}

const TimeTracker = () => {
  const [state, setState] = useSetState(defaultState)
  const {
    clockStart, isUserClockedIn, isUserInBreak, showLogs, showTimeTracker, userCurrentJob,
  } = state
  const [timerSeconds, setTimerSeconds] = useState(0)
  const [breakTimerSeconds, setBreakTimerSeconds] = useState(0)

  const { currentUser: { firstName }, timeZone } = useCurrentUser()
  const { currentUserV3 } = useCurrentUserV3()

  const includes = ['totalBreakTime', 'userBreakTimes', 'serviceJobUserTimes.serviceJob']
  useReduxAction('timetrackers', 'getUserTime', {
    filterStart: '-7d',
    filterEnd: '1d',
    includes: includes.join(','),
  }, [isUserClockedIn, isUserInBreak], {
    shouldPerformFn: (entityReducer) => {
      const { errors, loading: isLoading } = entityReducer
      return !isLoading && !errors.length && (!isUserClockedIn || !isUserInBreak)
    },
  })

  useEffect(() => {
    const interval = setInterval(() => {
      if (isUserClockedIn){
        if (isUserInBreak) setBreakTimerSeconds(seconds => seconds + 1)
        else setTimerSeconds(seconds => seconds + 1)
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [isUserClockedIn, isUserInBreak])

  const entityReducer = useSelector(reduxState => reduxState.timetrackers)
  const { loading, creating, result: timetrackers } = entityReducer

  const entities = useSelector(reduxState => reduxState.entities)
  const { services } = entities

  const {
    callbacks: {
      clockIn: clockInFn,
      clockOut: clockOutFn,
      startBreak: startBreakFn,
      endBreak: endBreakFn,
      getUserTime: getUserTimeFn,
    },
  } = useTimeTracker()

  const { callbacks: { jobTimeStop: jobTimeStopFn } } = useServiceJobTimeTracker()

  const showOngoingJobModal = (isJobStop) => {
    const lastShift = Object.values(timetrackers).pop()
    const currentJob = lastShift.serviceJobUserTimes.find(x => x.jobEnd === null)

    swal.fire({
      title: 'Stop Ongoing Job',
      html: 'Clicking YES will end your current ongoing job.<br>Do you wish to proceed?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      confirmButtonColor: '#e2001a',
      showClass: 'slide-from-top',
    }).then(({ value }) => {
      if (value){
        jobTimeStopFn(currentJob.serviceJob.id, { userRoleId: currentJob.serviceJobUserRoleId }).then(({ success, errors }) => {
          if (!success && errors){
            toast.warning(errors[0])
            return
          }
          (isJobStop ? clockOutFn() : startBreakFn()).then(({ success: successVal, errors: errorsVal }) => {
            if (!successVal && errorsVal) return
            if (isJobStop){
              setState({ isUserClockedIn: !isUserClockedIn })
              if (isUserClockedIn){
                setTimerSeconds(0)
                setBreakTimerSeconds(0)
              }
            } else setState({ isUserInBreak: !isUserInBreak })
            getUserTimeFn({
              filterStart: '-7d',
              filterEnd: '1d',
              includes: includes.join(','),
            })
          })
        })
      }
    })
  }

  const userClockInAndOut = () => {
    if (userCurrentJob && isUserClockedIn){
      showOngoingJobModal(true)
    } else {
      (isUserClockedIn ? clockOutFn() : clockInFn()).then(({ success, errors }) => {
        if (!success && errors) return

        if (isUserClockedIn){
          setTimerSeconds(0)
          setBreakTimerSeconds(0)
        }

        setState({ isUserClockedIn: !isUserClockedIn })
      })
    }
  }

  const userBreak = () => {
    if (userCurrentJob && !isUserInBreak){
      showOngoingJobModal(false)
    } else {
      (isUserInBreak ? endBreakFn() : startBreakFn()).then(({ success, errors }) => {
        if (!success && errors) return

        setState({ isUserInBreak: !isUserInBreak })
      })
    }
  }

  const autoClockOut = () => {
    clockOutFn().then(({ success, errors }) => {
      if (!success && errors) return
      setTimerSeconds(0)
      setBreakTimerSeconds(0)
      setState({ isUserClockedIn: false })
      swal.fire({
        title: 'Time Tracker Stopped',
        html: 'Open trackers are automatically closed<br>after 24 hours',
        icon: 'warning',
      })
    })
  }

  const contentEl = useRef()
  const [isClickedOutside, setIsClickedOutside] = useOutsideClick(contentEl, { enabled: showTimeTracker })

  useEffect(() => {
    if (showTimeTracker && isClickedOutside){
      setState({ showTimeTracker: !showTimeTracker })
      setIsClickedOutside(false)
    }
  }, [isClickedOutside])

  useEffect(() => {
    if (timetrackers){
      const currentShift = timetrackers.find(x => x.clockEnd === null) || Object.values(timetrackers).pop()

      const currentDate = DateTime.fromISO(DateTime.now(), { zone: timeZone })
      const currentStart = DateTime.fromISO(currentShift?.clockStart, { zone: timeZone })

      const diffTimeInSec = currentDate.diff(currentStart, ['seconds']).toObject()
      const totalBreaks = currentShift?.totalBreakTime ? Math.floor(currentShift?.totalBreakTime / 1000) : 0

      const currentBreak = currentShift?.userBreakTimes.pop()
      const inBreak = currentBreak && !currentBreak?.breakEnd
      const currentBreakIn = DateTime.fromISO(currentBreak?.breakStart, { zone: timeZone })
      const diffBreakInSec = currentDate.diff(currentBreakIn, ['seconds']).toObject()

      if (currentShift && !currentShift?.clockEnd){
        if (Math.floor(diffTimeInSec.seconds - totalBreaks) > 72000 && isUserClockedIn){
          const currentJob = currentShift.serviceJobUserTimes.find(x => x.jobEnd === null)

          if (currentJob){
            jobTimeStopFn(currentJob.serviceJob.id, { userRoleId: currentJob.serviceJobUserRoleId }).then(({ success, errors }) => {
              if (!success && errors) return
              autoClockOut()
            })
          } else { autoClockOut() }
        } else {
          setState({
            clockStart: currentShift?.clockStart,
            isUserClockedIn: !currentShift?.clockEnd,
            isUserInBreak: inBreak,
          })

          setTimerSeconds(Math.floor(diffTimeInSec.seconds - totalBreaks))
          setBreakTimerSeconds(Math.floor((inBreak ? diffBreakInSec.seconds : 0) + totalBreaks))
        }
      }
    }
  }, [timetrackers])

  useEffect(() => {
    if (showTimeTracker && isUserClockedIn){
      getUserTimeFn({
        filterStart: '-7d',
        filterEnd: '1d',
        includes: includes.join(','),
      }).then(({ success, data }) => {
        if (success){
          const currentShift = Object.values(data).pop()

          const runningJob = currentShift.serviceJobUserTimes.find(x => x.jobEnd === null)
          if (runningJob){
            setState({ userCurrentJob: runningJob.serviceJob })
          } else setState({ userCurrentJob: null })
        }
      })
    }
  }, [showTimeTracker])

  if (currentUserV3?.flags?.includes('Disable_Time_Tracker')){
    return null
  }

  return (
    <Box className={styles.root} ref={contentEl}>
      {loading ? <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ flexShrink: 0, minWidth: '50px' }} /> : (
        <Box flexDirection="row" alignItems="center">
          <Text
            color="bodyFontLightColor"
            onClick={() => setState({ showTimeTracker: !showTimeTracker })}
            style={{ cursor: 'pointer' }}
          >
            {secondsToTimeFormat(timerSeconds)}
          </Text>
          <Box
            alignItems="center"
            backgroundColor={isUserInBreak ? 'whiteGrey' : (!isUserClockedIn ? 'green' : 'red')}
            borderRadius="1rem"
            height={30}
            justifyContent="center"
            marginLeft="medium"
            onClick={isUserInBreak ? null : userClockInAndOut}
            style={{ cursor: isUserInBreak ? null : 'pointer' }}
            width={30}
          >
            <FontAwesomeIcon icon={!isUserClockedIn ? faPlay : faStop} color="white" size="sm" />
          </Box>
        </Box>
      )}
      {showTimeTracker && (
        <Box className={styles.menu}>
          {!isUserClockedIn ? (
            <Box
              flexDirection="column"
              borderBottom="1px solid"
              borderColor="lineColor"
              alignItems="center"
              padding="xlarge"
            >
              <Text fontSize="medium" fontWeight="bold" marginBottom="medium">
                Welcome back {firstName}!
              </Text>
              <Text fontSize="small" color="bodyFontLightColor" marginBottom="large">
                Don&apos;t forget to track your time.
              </Text>
              <Button size="medium" buttonStyle="primaryCreate" onClick={userClockInAndOut} loading={creating || loading}>
                CLOCK IN
              </Button>
            </Box>
          ) : (
            <>
              <Box
                alignItems="center"
                borderBottom="1px solid"
                borderColor="lineColor"
                flexDirection="column"
                justifyContent="center"
                padding="large"
              >
                <Text fontSize="25px" fontWeight="bold" marginBottom="large">
                  {secondsToTimeFormat(timerSeconds)}
                </Text>
                <Box flexDirection="row">
                  <Button
                    buttonStyle="primaryDestroy"
                    disabled={isUserInBreak}
                    loading={creating || loading}
                    onClick={userClockInAndOut}
                    size="medium"
                    style={{ marginRight: 8, width: 'calc(50% - 4px)' }}
                  >
                    CLOCK OUT
                  </Button>
                  <Button
                    buttonStyle="primaryEdit"
                    loading={creating || loading}
                    onClick={userBreak}
                    size="medium"
                    style={{ width: 'calc(50% - 4px)' }}
                  >
                    {isUserInBreak ? 'END' : 'START'} BREAK
                  </Button>
                </Box>
              </Box>
              <Box
                alignItems="center"
                borderBottom="1px solid"
                borderColor="lineColor"
                flexDirection="column"
                fontSize="small"
                padding="large"
                textAlign="center"
              >
                <Text size="xsmall">
                  {creating || loading ? '...' : userCurrentJob && Object.keys(services).length !== 0
                  ? (
                    <Link href={`/service-jobs/${userCurrentJob.id}`} target="_self" style={{ textDecoration: 'none' }}>
                      {userCurrentJob.campaign.name}<br />{services[userCurrentJob.serviceId]?.name}
                    </Link>
                  )
                  : 'No ongoing job'}
                </Text>
              </Box>
              <Box
                alignItems="center"
                borderBottom="1px solid"
                borderColor="lineColor"
                flexDirection="column"
                fontSize="small"
                padding="large"
              >
                <Box flexDirection="row" textAlign="center">
                  <Box flexDirection="column">
                    <Text color="bodyFontLightColor" size="xsmall" marginBottom="small">
                      Clocked In
                    </Text>
                    <Text size="xsmall">
                      {DateTime.fromISO(clockStart || DateTime.now(), { zone: timeZone }).toFormat('hh:mm a')}
                    </Text>
                  </Box>
                  <Box flexDirection="column">
                    <Text color="bodyFontLightColor" size="xsmall" marginBottom="small">
                      Breaks Taken
                    </Text>
                    <Text size="xsmall">{secondsToTimeFormat(breakTimerSeconds)}</Text>
                  </Box>
                </Box>
              </Box>
            </>
          )}
          <Box flexDirection="column" padding="large" alignItems="center" fontSize="small">
            <Link onClick={() => setState({ showLogs: !showLogs })}>
              {showLogs ? 'Hide' : 'View'} Logs
            </Link>
          </Box>
          {showLogs ? <Logs timetrackers={timetrackers} /> : null}
        </Box>
      )}
    </Box>
  )
}

export default TimeTracker
