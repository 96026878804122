/* eslint-disable array-callback-return */
import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useSetState } from '@campaignhub/react-hooks'

import useReduxAction from '@hooks/useReduxAction'
import useCurrentUser from '@hooks/useCurrentUser'

import { Box, LoadingBubbles, Text, Tag } from '@campaignhub/suit-theme'

import ServiceGroup from './components/ServiceGroup'

const defaultState = {
  filteredServiceGroups: [],
}

const ServiceTypeFilter = (props) => {
  const { filterJob, setJobState, jobState } = props
  const { accountManagerFilter, classificationFilter, clientFilter, filterTags, serviceDateFilter,
    serviceFilter, serviceUserFilter, statusFilter, userFilter, customFilter } = jobState

  const [state, setState] = useSetState(defaultState)
  const { filteredServiceGroups } = state

  const { currentUser, currentUser: { services: currentServices } } = useCurrentUser()

  const entities = useSelector(reduxState => reduxState.entities)
  const { serviceGroups, users, services } = entities

  const entityReducer = useSelector(reduxState => reduxState.serviceGroups)
  const { loading } = entityReducer

  const options = {
    ...accountManagerFilter?.length && { accountManagers: accountManagerFilter.join(',') },
    ...classificationFilter?.length && { classifications: classificationFilter.join(',') },
    ...clientFilter?.length && { clients: clientFilter.join(',') },
    ...customFilter?.length && { customFilters: customFilter.join(',') },
    ...filterTags?.length && { tags: filterTags.join(',') },
    ...serviceDateFilter?.filterEnd && { serviceDateFilterEnd: serviceDateFilter.filterEnd },
    ...serviceDateFilter?.filterStart && { serviceDateFilterStart: serviceDateFilter.filterStart },
    ...serviceFilter?.length && { services: serviceFilter.join(',') },
    ...statusFilter?.length && { serviceJobStatuses: statusFilter.join(',') },
    ...userFilter?.length && { users: userFilter.join(',') },
    ...serviceUserFilter?.length && { serviceUsers: serviceUserFilter.join(',') },
  }

  useReduxAction('serviceJobFilter', 'loadServiceJobCounts', options, [filterJob])

  useEffect(() => {
    let temp = []

    if (['BackOffice'].includes(users[currentUser.id]?.userRole.name)){
      temp = Object.values(serviceGroups)
      setState({ filteredServiceGroups: temp })
      return
    }

    if (Object.values(serviceGroups).length){
      currentServices.map((serviceId) => {
        const group = Object.values(serviceGroups).find(serviceGroup => serviceGroup.services.find(service => service === serviceId))
        if (group) temp.push(group)
      })

      temp = [...new Set(temp)]
    }

    temp.map((serviceGroup) => {
      const group = serviceGroup
      const filteredServices = serviceGroup.services.filter(service => currentServices.includes(service))
      group.services = filteredServices

      return group
    })

    setState({ filteredServiceGroups: temp })
  }, [currentServices, serviceGroups])

  return (
    <Box flexDirection="column">
      <Box flexDirection="row" marginBottom="large">
        <Box alignItems="center">
          <Text
            color="bodyFontLightColor"
            fontAlign="center"
            fontSize="small"
            whiteSpace="nowrap"
          >
            Service Types
          </Text>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems="center">
          <Tag
            boxProps={{
              width: 'fit-content',
              fontSize: 'xsmall',
              marginRight: 'large',
            }}
          >
            Selected: {jobState.serviceFilter?.map(x => services[x]?.jobCount)?.reduce((sum, a) => sum + a, 0)}
          </Tag>
        </Box>
      </Box>
      {loading && <LoadingBubbles color="rgba(0, 0, 0, 0.15)" style={{ flexShrink: 0 }} />}
      {!filteredServiceGroups.length && !loading && (
        <Box flexDirection="column">
          <Text color="bodyFontLightColor" fontAlign="center" fontSize="xsmall">
            No Available Services
          </Text>
        </Box>
      )}
      {filteredServiceGroups.map(serviceGroup => (
        <ServiceGroup key={serviceGroup.id} serviceGroup={serviceGroup} setJobState={setJobState} serviceFilter={serviceFilter} />
      ))}
    </Box>
  )
}

ServiceTypeFilter.propTypes = {
  filterJob: PropTypes.number,
  setJobState: PropTypes.func,
  jobState: PropTypes.object,
}

export default ServiceTypeFilter
