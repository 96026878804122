import { useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'
import {
  useLatestEntity, useThunkDispatch, useWatchEntityUpdates, useSetState,
} from '@campaignhub/react-hooks'

import useCurrentUser from '@hooks/useCurrentUser'
import userTypes from '@functions/userTypes'

import * as serviceJobActions from '@redux/modules/serviceJob'
import * as assetActions from '@redux/modules/asset'

import PageContext from '@contexts/pageContext'
import api from '@functions/api'
import { handleError } from '@redux/utils'

const defaultState = {
  showDuskPositional: false,
}

const watchEntityKeys = ['serviceJobUsers']

const assignServiceJobStatus = (serviceJobParam, dispatch, requestOptions) => {
  const { assignServiceJobStatus: createFn } = serviceJobActions

  const includes = ['service', 'campaign', 'client', 'details', 'histories', 'users', 'notes', 'tags']
  const serviceJobOptions = {
    includes: includes.join(','),
  }

  return dispatch(createFn(serviceJobParam, requestOptions, serviceJobOptions))
}

const createInitialCopyAsset = (serviceJobParam, dispatch, requestOptions) => {
  const { createInitialCopyAsset: generateFn } = serviceJobActions

  return dispatch(generateFn(serviceJobParam, requestOptions))
}

const loadServiceJobAssets = (serviceJobParam, dispatch, requestOptions) => {
  const { loadAssets: loadFn } = assetActions

  return dispatch(loadFn(serviceJobParam, requestOptions))
}

const updateServiceJobTotalAssets = (serviceJobParam, dispatch, requestOptions) => {
  const { updateServiceJobTotalAssets: updateFn } = serviceJobActions

  const includes = ['service', 'campaign', 'client', 'contentDirectProviders', 'details', 'histories', 'users', 'notes', 'postProductionChecklistItems', 'tags']
  const serviceJobOptions = {
    includes: includes.join(','),
  }

  return dispatch(updateFn(serviceJobParam, requestOptions, serviceJobOptions))
}

const updateServiceJob = (serviceJobParam, dispatch, requestOptions) => {
  const { updateServiceJob: updateFn } = serviceJobActions

  const includes = ['service', 'campaign', 'client', 'contentDirectProviders', 'details', 'histories', 'users', 'notes', 'postProductionChecklistItems', 'tags']
  const serviceJobOptions = {
    includes: includes.join(','),
  }

  return dispatch(updateFn(serviceJobParam, requestOptions, serviceJobOptions))
}

const patchServiceJob = (serviceJobParam, dispatch, requestOptions) => {
  const { patchServiceJob: patchFn } = serviceJobActions

  const includes = ['service', 'campaign', 'client', 'contentDirectProviders', 'details', 'histories', 'users', 'notes', 'postProductionChecklistItems', 'tags']
  const serviceJobOptions = {
    includes: includes.join(','),
  }

  return dispatch(patchFn(serviceJobParam, requestOptions, serviceJobOptions))
}

const launchEditServiceJobDetailsModal = (serviceJob, dispatch, showEditServiceJobDetailsModal) => new Promise((resolve, reject) => {
  if (showEditServiceJobDetailsModal){
    const payload = {
      callbacks: {
        patchServiceJob: (serviceJobParam, requestOptions) => patchServiceJob(serviceJobParam, dispatch, requestOptions),
      },
      serviceJob,
    }

    showEditServiceJobDetailsModal(payload)

    return resolve({ success: true, result: payload })
  }

  return reject(new Error('showEditServiceJobDetailsModal not defined in PageContext callbacks'))
})

const useServiceJob = (initServiceJob = {}) => {
  const { entity: serviceJob } = useLatestEntity(initServiceJob, 'serviceJobs')
  const { currentUser } = useCurrentUser()

  const [state, setState] = useSetState(defaultState)
  const { showDuskPositional } = state

  const {
    updatedEntities: {
      serviceJobUsers: serviceJobUsersUpdatedAt,
    },
  } = useWatchEntityUpdates(watchEntityKeys)

  const dispatch = useThunkDispatch()

  const { callbacks } = useContext(PageContext)
  const { showEditServiceJobDetailsModal } = callbacks || {}

  const {
    creating, deleting, loading, updating, additionalInfo,
  } = useSelector(reduxState => reduxState.serviceJobs)

  const entities = useSelector(reduxState => reduxState.entities)
  const { resourceTypes, serviceJobUsers, services, serviceGroups, serviceJobs } = entities

  const filteredServiceJobUsers = useMemo(() => {
    const filtered = Object.values(serviceJobUsers).filter(x => x.serviceJobId === serviceJob.id)

    return filtered
  }, [serviceJobUsersUpdatedAt, serviceJob.id])

  const filteredServiceResourceTypes = useMemo(() => {
    const duskServices = ['Dusk Photography']

    const duskServiceJobs = Object.values(serviceJobs).filter(x => duskServices.includes(x.service?.name)
      && x.campaign?.id === serviceJob?.campaign?.id)
    const duskResourceTypeIds = Object.values(resourceTypes).filter(x => x.name === 'DuskPositional')[0]?.id
    // default get of resourcetypes
    const serviceResourceTypeIds = Object.values(services).find(x => x.id === serviceJob.service?.id)?.resourceTypes

    const floorplanCAD = Object.values(resourceTypes).find(x => x.name === 'FloorplanCAD')?.id
    const floorplanPredraw = Object.values(resourceTypes).find(x => x.name === 'FloorplanSitePlanPreDraw')?.id

    const filterResourceTypeIds = () => {
      if (((currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'OnSite')?.id
        || currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'Studio')?.id)
        && duskServiceJobs.length > 0
        && !duskServices.includes(serviceJob.service?.name)
        && ['Photography'].includes(serviceGroups[serviceJob.serviceGroupId]?.name))){
        setState({ showDuskPositional: true })
        return serviceResourceTypeIds?.concat(duskResourceTypeIds)
      }

      if ((currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'OnSite')?.id) && floorplanCAD !== null){
        return serviceResourceTypeIds?.filter(x => x !== floorplanCAD && x !== floorplanPredraw)
      }

      return serviceResourceTypeIds
    }

    const filteredResourceTypeIds = filterResourceTypeIds()

    const serviceResourceTypes = serviceResourceTypeIds ? Object.values(resourceTypes).filter(x => filteredResourceTypeIds.includes(x.id)) : []
    const filtered = (currentUser?.userRole.userTypeId === userTypes.find(x => x.name === 'OnSite')?.id
      && ['Photography Image Selector', 'Prestige Photography with Companion'].includes(serviceJob.service?.name)
      && serviceResourceTypeIds) ? serviceResourceTypes.filter(x => x.id === 11 && filteredResourceTypeIds.includes(x.id)) : serviceResourceTypes

    return filtered
  }, [serviceJob.id, resourceTypes, services])

  const fetchServiceJobs = (options, callback) => {
    api('/v1/service-jobs', options)
      .then((data) => {
        callback({ success: true, data: data.data })
      })
      .catch((data) => {
        const errors = handleError(data)
        callback({ success: false, result: errors })
      })
  }

  return {
    callbacks: {
      assignServiceJobStatus: (serviceJobParam, requestOptions) => assignServiceJobStatus(serviceJobParam, dispatch, requestOptions),
      createInitialCopyAsset: (serviceJobParam, requestOptions) => createInitialCopyAsset(serviceJobParam, dispatch, requestOptions),
      launchEditServiceJobDetailsModal: () => launchEditServiceJobDetailsModal(serviceJob, dispatch, showEditServiceJobDetailsModal),
      loadServiceJobAssets: (serviceJobParam, requestOptions) => loadServiceJobAssets(serviceJobParam, dispatch, requestOptions),
      patchServiceJob: (serviceJobParam, requestOptions) => patchServiceJob(serviceJobParam, dispatch, requestOptions),
      updateServiceJobTotalAssets: (serviceJobParam, requestOptions) => updateServiceJobTotalAssets(serviceJobParam, dispatch, requestOptions),
      updateServiceJob: (serviceJobParam, requestOptions) => updateServiceJob(serviceJobParam, dispatch, requestOptions),
      fetchServiceJobs: (requestOptions, callback) => fetchServiceJobs(requestOptions, callback),
    },
    creating,
    deleting,
    filteredServiceJobUsers,
    filteredServiceResourceTypes,
    loading,
    serviceJob,
    updating,
    showDuskPositional,
    additionalInfo,
  }
}

export default useServiceJob
